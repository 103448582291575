.product-price .subscription-details {
  font-size: 11px;
}

.product-price .subscription-details {
  font-size: 11px;
}

.woocommerce-cart {
  .vermas.seguircomprandofix {
    width: 100%;
    margin-bottom: -5px !important;
    text-align: center;
    margin-top: 15px !important;
  }

  &.woocommerce-page .texto-switch .tooltip-wrap .tooltip {
    right: -24px;
  }

  #abrir-lightbox {
    display: none;
  }

  #shipping-alerta {
    padding-left: 55px;
    color: $turquesa_base;
    line-height: 25px;
    font-size: 13px;
    position: relative;
    margin-bottom: 30px;

    i {
      position: absolute;
      font-size: 27px;
      color: $dorado;
      left: 10px;
      top: 10px;
    }
  }

  .wcsatt-add-cart-to-subscription-form {
    display: none;
  }

  .woocommerce table.shop_table {
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0;

    .woocommerce-remove-coupon {
      color: $dorado;
      padding-left: 10px;
    }
  }

  .cart td {
    padding: 10px 10px !important;

    .texto-switch {
      float: right;
      position: relative;
      top: 5px;
      text-align: left;
    }

    .amount {
      font-size: 14px;
    }

    &.actions {
      background-color: transparent;
    }
  }

  .product-subtotal .texto-switch {
    width: 146px;
  }

  .woocommerce a.remove {
    font-weight: normal;
    font-size: 30px;

    &:hover {
      background-color: transparent;
      color: red !important;
    }
  }

  .complemento-update {
    font-weight: lighter;
    font-size: 13px;
    text-align: left;
    color: $turquesa_base;
    margin-bottom: 0;
  }

  .actualizarcarro {
    background-color: transparent;
    text-transform: none;
    color: $turquesa_base;
    font-weight: lighter;
    width: 100%;
    border: 0;
    text-decoration: underline;
    margin-bottom: 0;

    i {
      margin-right: 5px;
    }
  }

  table.cart img {
    width: 80px !important;
  }

  .woocommerce .cart-collaterals .cart_totals,
  &.woocommerce-page .cart-collaterals .cart_totals {
    span {
      font-size: 13px
    }

    width: 85%;
    background-color: #FFF;
    border: 20px solid #FFF;
    padding: 0 10px;
    color: #000;

    h2 {
      font-family: $poppins;
      font-size: 14px;
      color: $turquesa_base;
      margin-bottom: 0px;
    }

    th {
      font-weight: 600;
      padding-left: 0;
      color: #28474f !important;
      font-size: 12px;
      line-height: 16px
    }

    td {
      text-align: right;
      padding-right: 0;
      font-size: 12px;
      color: #28474f;
    }

    tr.order-total .amount {
      color: $turquesa_base;
    }

    tr.recurring-totals:not(.order-total) {
      th:first-child {
        font-weight: bold;
        padding-top: 35px !important;
      }
    }

    #mensaje {
      padding-top: 10px;
    }

    #si_joker {
      font-size: 12px;
      color: $dorado;
      text-decoration: underline;
      display: inline;

      a {
        color: $dorado;
      }
    }

    #si_joker2 {
      color: $dorado;
      display: inline;
      float: right;
      font-size: 13px;
    }

    .recurring-totals th {
      font-size: 13px !important;
      letter-spacing: 0.2px;
      text-transform: uppercase;
    }
  }

  .coupon {
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;

    #coupon_code {
      border: 0;
      font-size: 12px;
      letter-spacing: 0.3px;
      max-width: 260px;
    }

    button {
      background-color: transparent !important;
      right: -28px;

      span {
        color: $dorado;
      }
    }
  }

  .style-msg.successmsg .sb-msg {
    margin: 30px 0;
  }

  #interes-carrusel {
    padding: 0 50px;

    .portfolio-item {
      width: 100%;

      img {
        max-width: 200px;
        margin: 0 auto;
      }
    }

    .woocommerce-loop-product__title {
      font-family: $poppins;
      font-size: 13.6px;
      text-align: center;
      margin-bottom: 0;
      color: $turquesa_base;
      margin-top: 10px;
      margin-bottom: 2px;
    }

    .oc-item {
      .back-blanco {
        padding: 20px 0 10px;
        position: relative;
        text-align: center;

        .back-blanco {
          padding: 0;
        }
      }

      img {
        margin: 0 auto;
        max-width: 200px;
        margin-bottom: 20px;
      }

      .price .amount {
        color: $turquesa_base;
        font-weight: 500;
        font-size: 14.6px;
        padding-bottom: 0;
        border: 0;
        text-align: left;
      }

      .portfolio-item {
        width: 100%;
      }

      .price {
        width: 100% !important;
        display: block !important;
        text-align: center !important;
      }

      .woocommerce-loop-product__title {
        font-family: $poppins;
        font-size: 13.6px;
        text-align: center;
        margin-bottom: 20px;
        color: $turquesa_base;
        padding: 0 10px;
      }

      .portfolio-item .woocommerce-loop-product__title {
        text-align: left;
        padding: 0 20px;
      }
    }
  }

  .cart-empty {
    font-size: 30px;
    font-family: $poppins;
    color: $dorado;
    font-weight: bold;
    line-height: 40px !important;
    text-align: center;
    margin-bottom: 0;
    padding-top: 80px;
  }



  &.woocommerce-page .woocommerce-message {
    top: 0px;
  }

  &.woocommerce-page .woocommerce-error {}


  .vermas {
    background-color: transparent !important;
    border-radius: 0 !important;
    margin: auto;
  }

  .woocommerce-loop-product__title {
    font-family: $poppins;
    font-size: 13.6px;
    text-align: center;
    margin-bottom: 0;
    color: $turquesa_base;
    margin-top: 16px;
    margin-bottom: 2px;
  }

  .price {
    width: 100% !important;
    display: block !important;

  }
}

/* Mini cart */

#top-cart {
  margin-bottom: 0px !important;

  .fix {
    top: 0px !important;
  }

  .top-cart-items1 .top-cart-items1 {
    max-height: 175px;
    overflow-y: auto;
    position: relative;
  }

  .top-cart-items,
  .top-cart-action {
    padding: 8px 0px;
  }

  .top-cart-item {
    padding: 8px 10px 0 !important;
    margin-top: 8px !important;
    margin-top: -15px;

    ul.wcsatt-options {
      display: none;
    }
  }

  .woocommerce-mini-cart__buttons.buttons {
    margin-bottom: 0px;
    margin-top: 15px;
    border-top: 1px solid #F5F5F5;
    padding-top: 15px;

    .vermas {
      text-transform: none;
    }
  }

  .top-cart-content::after {
    content: "";
    background-image: url(imagenes/corte-smoke.png);
    background-size: cover;
    position: absolute;
    top: -32px;
    right: -1px;
    width: 32px;
    height: 32px;
  }

  .top-cart-items {
    padding: 15px 10px;
  }

  p.woocommerce-mini-cart__empty-message {
    text-align: center;
    margin-bottom: 0;
    padding: 10px 0;
    font-size: 13px
  }

  .mensajepersonalizado {
    background-color: #333333;
    text-align: center;

    p {
      letter-spacing: 0px;
      color: #fff;
      margin: auto;
      font-size: 11px;
      letter-spacing: 0.4px;
      line-height: 17px !important;
      padding: 6px 16px;
    }
  }

  .precio_producto {
    float: right;

    .woocommerce-Price-amount.amount {
      font-weight: 600;
      color: #28474f;
      font-size: 12px
    }
  }

  a.vermas {
    font-weight: 700 !important;
    width: 230px !important;
    margin: 4px auto !important
  }

  a {
    width: unset !important;
    height: unset !important;
    text-transform: uppercase;
  }

  .top-cart-content {
    width: 350px;
    left: -332px;
    top: 62px;
  }

  .mini_cart_item .remove_from_cart_button:hover {
    background-color: $dorado;
    color: #fff !important;
  }

  .mini_cart_item .remove_from_cart_button {
    position: absolute;
    right: 0;
    color: $dorado !important;
    font-weight: lighter;
    font-size: 23px;
    width: 20px !important;
    height: 20px !important;
    z-index: 99;
    margin-top: 0px;
    right: 15px;
  }

  .top-cart-item-image {
    margin-right: 10px;
    border: unset;
    width: 54px !important;
    height: 54px !important;

    a,
    img {
      width: 54px !important;
      height: 54px !important;
    }
  }

  .top-cart-item-quantity {
    margin-bottom: 0px;
    font-family: $poppins;
    font-weight: 600;
    color: #28474f;
    width: 115px;
    display: inline-block;
    line-height: 18px;
    letter-spacing: 0px;
    font-size: 12px
  }

  h4 {
    font-family: $poppins;
  }

  a#top-cart-trigger {
    span {
      background-color: $dorado;
      text-shadow: none !important;
    }
  }

  .subtotal.clearfix {
    padding: 10px 50px 0 75px;

    p {
      margin-bottom: 0;
      color: $turquesa_base;
    }

    .subtotal_precio {
      color: $dorado;
      font-weight: 600;
    }
  }
}


html .woocommerce-cart {
  .woocommerce-cart-form {
    >button.button {
      display: none;
    }

    .quantity {
      margin-right: 0px
    }

    .product-name a {
      font-weight: 600 !important;
      font-size: 12.6px;
      color: #28474f;
      width: 200px;
      display: inline-grid;
    }

    .product-name .woocommerce-Price-amount {
      color: $turquesa_base;
      font-size: 14px !important;
      font-weight: lighter !important;
    }

    .product-remove {
      padding: 0px 0px 0px 10px !important;
      margin: 0px !important
    }

    .checkout-button {
      width: 100%
    }

    .subscription-price .subscription-details {
      color: #666;
      font-weight: lighter;
    }

    .amount {
      color: $turquesa_base;
      font-size: 12.6px !important
    }

    a.remove,
    a.remove:hover {
      color: $dorado !important;
      font-size: 20px !important
    }
  }

  .cart_totals .checkout-button {
    width: 100%;
    text-transform: uppercase
  }

  .shop_table {
    .cart-subtotal {
      display: none;
    }
  }


}

.woocommerce-cart #interes-carrusel {
  span.wcsatt-sub-discount {
    display: none;
  }

  .owl-dots {
    display: none;
  }

  .product {
    width: 100%;
  }
}


#wc-memberships-members-area-section {
  span.wcsatt-sub-discount {
    display: none;
  }
}

.top-cart-item-desc .subscription-details {
  display: none
}

td.product-price span {
  font-size: 12px;
  font-weight: 600;
  color: #28474f
}

.pjokers,
.pnormal {
  width: 100%;
  display: block;
}

.pjokers del,
.pjokers span {
  font-size: 14px !important;
}

.ultramini {
  position: relative;
  top: -8px;
  letter-spacing: 0.5px;
  font-size: 11px;
  color: #777;

  &.ultraminiv1 {
    top: -18px;
  }
}

.woocommerce-cart .bootstrap-switch {
  position: relative;
  left: 20px
}

.woocommerce-cart .tipodecompra {
  font-size: 12px
}

.woocommerce-cart .cart_totals {
  position: relative;
  top: -160px
}

.woocommerce-cart .cart_totals tr.shipping {
  display: none
}


.texto-switch {
  text-align: right;
}

.quantity .qty {
  color: #28474f
}

.wc-proceed-to-checkout {

  h2,
  .seguircomprandofix {
    display: none
  }

}

#productosdeimpulso {
  margin-top: 40px
}

body.woocommerce-cart {
  .woocommerce-cart-form__cart-item {
    background: #fff !important
  }

  .texto-switch .tooltip-wrap span {
    font-weight: 500 !important
  }

  .toggle {
    display: block !important;
    margin: 0 0 10px 0
  }

  .togglet {
    margin: 0px;
    padding: 0px;
    color: #676767;
    font-weight: 400;
    font-size: 12.6px !important;
    position: relative;
    top: -4px;
    letter-spacing: 0px;
    font-weight: 500;
    display: inline-block;
    text-decoration: underline;
  }

  .togglec {
    padding: 4px 0px 0px 0px;
  }

  .quantity .qty {
    border: 1px solid #e5e5e5
  }
}

.button-social-login:focus .si,
.button-social-login:hover .si,
.widget-area .widget a.button-social-login:focus .si,
.widget-area .widget a.button-social-login:hover .si,
a.button-social-login:focus .si,
a.button-social-login:hover .si,
button.button-social-login:focus .si,
button.button-social-login:hover .si,
input.button-social-login:focus .si,
input.button-social-login:hover .si {
  background-color: #fff !important
}

.wc-social-login>p {
  display: none
}

.wc-social-login.form-row-wide {
  text-align: center
}

.woocommerce-account .wc-social-login {
  margin-top: 36px
}

.button-social-login:active,
.widget-area .widget a.button-social-login:active,
a.button-social-login:active,
button.button-social-login:active,
input.button-social-login:active {
  top: 0px
}

del {
  text-decoration: none !important
}



/* SAVE LATER   */

.cart-and-save.save-later {
  .woosl_table.close {
    max-width: 100% !important;
  }

  .woosl_table {
    max-width: 580px !important;
    margin-left: 0px !important;
    margin-top: 0px !important;

    thead {
      th {
        color: #28474f;
        font-weight: 500 !important;
        font-size: 14px !important;
        text-shadow: none !important;
      }
    }

    .woosl-add {
      button {
        background-color: #28474f;
        color: #fff;
        padding: 0px 4px !important
      }
    }

    .woosl-thumbnail {
      width: 70px !important
    }

    .woosl-name .woosl-btn {
      button {
        font-size: 10px !important;
      }
    }

  }


}

.save-later {
  .no-save-products {
    display: none !important
  }

  .woosl_table {
    max-width: 700px;
    margin: 40px auto !important;

    thead {
      th {
        color: #28474f;
        font-weight: 600 !important;
        font-size: 20px !important
      }
    }

    .woosl-add {
      button {
        background-color: #28474f;
        color: #fff;
      }


    }

    .woosl-price,
    .woosl-stock {
      display: none;
    }
  }
}



.woocommerce-cart {
  .product-name {
    div {
      display: none
    }

    a {
      line-height: 1.2;
    }
  }

  .woosl-btn {
    display: block !important;
    text-align: left;
    width: 200px;
  }

  .woosl-button {
    height: auto;
    text-shadow: none;
    font-weight: 500 !important;
    letter-spacing: 0px;
    padding: 5px !important;
    margin: 0px;
    font-size: 11px !important;
    width: auto !important;
    background: transparent;
    border: 1px solid #28474f;
    color: #28474f;

    &.add {
      margin-top: 4px;
    }

    &.remove {
      border: 1px solid #28474f
    }
  }


}




.no-save-products {
  margin: 100px 0px;

  h2 {
    margin-bottom: 0px !important
  }

  .return-to-shop {
    text-align: center;
  }

}


@media (max-width: 768px) {
  .save-later.cart-and-save {
    .woosl_table {
      .woosl-name {
        text-align: left !important
      }
    }
  }

  .save-later {
    .woosl_table {
      thead {
        display: block !important
      }

      tr td::before {
        display: none !important
      }

      .woosl-name {
        a {
          width: 100% !important;
          text-align: left !important;
          display: block !important;
          margin-bottom: 6px
        }
      }


    }



  }






  .cart-and-save.save-later .woosl_table thead {
    display: block !important;
  }



}

.members-area {
  .woocommerce-account-my-memberships {
    thead {
      display: inline-block !important;
      width: 50%;
      position: relative;
      top: -5px;

      @media (max-width: 1370px) {
        top: -12px;
      }

    


      tr {
        width: 100%;
        display: block
      }

      th {
        display: block !important;
        border-top: 1px solid rgba(0, 0, 0, .1) !important;
        padding: 10px 0px !important
      }
    }

    tbody {
      display: inline-block;
      width: 49%;

      tr {
        width: 100%;
        display: block
      }

      td {
        display: block !important;
        border-top: 1px solid rgba(0, 0, 0, .1) !important;
      }

    }

  }

}