/*-----------------------------------------------------------------------------------

    Theme Name: Smoke Jokers
    Author: Fixed by Kraneo Studio
    Author URI: https://kraneo.studio
    Version: 5.1

    LESS Stylesheet

-----------------------------------------------------------------------------------*/


// Initialize
@import "sass/variables.scss";
@import "sass/mixins.scss";


// Core CSS
@import "sass/typography.scss";
@import "sass/helpers.scss";
@import "sass/layouts.scss";


// Content Blocks
@import "sass/topbar.scss";
@import "sass/header.scss";
@import "sass/sliders.scss";
@import "sass/pagetitle.scss";
@import "sass/content.scss";
@import "sass/portfolio.scss";
@import "sass/blog.scss";
@import "sass/shop.scss";
@import "sass/events.scss";


// Shortcodes
@import "sass/shortcodes.scss";


// Footer
@import "sass/footer.scss";


// Widgets
@import "sass/widgets.scss";


// Extras
@import "sass/extras.scss";

// Smoke
@import "sass/smoke/menu.scss";
@import "sass/smoke/seguridad.scss";
@import "sass/smoke/home.scss";
@import "sass/smoke/preguntas.scss";
@import "sass/smoke/puntos-retiro.scss";
@import "sass/smoke/tienda.scss";
@import "sass/smoke/producto.scss";
@import "sass/smoke/carrito.scss";
@import "sass/smoke/checkout.scss";
@import "sass/smoke/login.scss";
@import "sass/smoke/orden.scss";
@import "sass/smoke/micuenta.scss";
@import "sass/smoke/responsive-smoke.scss";







