.woocommerce-form-coupon-toggle {
  display: none;
}

.woocommerce-billing-fields__field-wrapper {
  border-top: 1px solid rgba(0, 0, 0, .1);
}

.woocommerce-order-pay {
  .order_details {
    background-color: #fff !important;
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 20px !important;
    display: none;

    li {
      font-weight: 700;
      font-size: 13px !important;
      ;
      color: #28474f !important;
      text-transform: capitalize !important;
      letter-spacing: 0.6px !important;
      border-right: 1px solid #d3ced2 !important;

      strong {
        font-weight: 400 !important;
        letter-spacing: 0.6px !important;
        font-size: 13px !important;
      }
    }
  }
}

.woocommerce-order-overview.woocommerce-thankyou-order-details.order_details {
  background-color: #fff !important;
  border: 1px solid rgba(0, 0, 0, .1);
  padding: 20px !important;

  li {
    font-weight: 700;
    font-size: 13px !important;
    color: #28474f !important;
    text-transform: capitalize !important;
    letter-spacing: 0.6px !important;
    border-right: 1px solid #d3ced2 !important;

    @media (max-width: 991px) {
      width: 100%;
      margin-bottom: 10px;
      border: none !important;
    }

    strong {
      font-weight: 400 !important;
      letter-spacing: 0px !important;
      font-size: 12px !important;
      margin-top: 4px
    }
  }
}

#oneclick {
  padding: 40px 0px;

  h1 {
    font-weight: bold;
    color: #28474f;
    font-family: "Poppins", sans-serif
  }

  p {
    text-align: center;
    max-width: 600px;
    margin: auto;
  }
}

.woocommerce-checkout {
  .formulariodelogin::after {}

  .woocommerce form.login {
    border: 0px !important;

    p {
      width: 100% !important;
      text-align: left;
      margin-bottom: 10px !important;

      input {
        text-align: left;
        width: 60% !important;
      }
    }

    .vermas {
      display: block;
    }
  }

  .modal-body {
    padding: 60px 80px !important;
  }

  .modal-header {
    border-bottom: 0px !important;

    .close {
      position: absolute;
      right: 20px;
    }

    h4 {
      font-weight: bold;
      margin-bottom: 20px;
      font-family: "Poppins";
      letter-spacing: 1px;
      color: #28474f;
      font-size: 30px;
      margin: auto;
      display: block;
      position: relative;
      top: 30px;
    }

    p {
      font-weight: lighter;
      color: #28474f;
      font-family: "Poppins" !important;
    }
  }

  .modal-content {
    border-radius: 0px !important;
  }

  .woocommerce-form-login {
    padding: 0 !important;
    width: 100%;
    border: 0;
    border-bottom: 1px solid #d3ced2;

    .woo-slg-social-container {
      width: 100%;
      text-align: center;
    }

    .woo-slg-social-btn {
      min-width: 1px !important;
      padding-left: 25px !important;
      max-width: 25px !important;

      .woo-slg-icon {
        border-right: 0;
      }
    }
  }

  .titulo-seccion1 {
    margin-bottom: 0px;
    font-size: 22px;
    font-family: "Poppins", sans-serif;
    font-weight: normal;
  }
}

form.checkout {
  label {
    font-family: $poppins;
    font-weight: lighter;
    font-size: 14px;

    &.checkbox {
      padding-left: 0;
    }
  }

  .woocommerce-input-wrapper {
    width: 100%;
  }

  input,
  textarea {
    border: 0;
    padding: 10px;
    margin: 10px 0 !important;
  }

  input[type="radio"]:not(.input-radio) {


    &:first-child {
      margin-left: 0 !important;
    }
  }

  h4,
  h3#ship-to-different-address {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }

  textarea {
    height: 120px !important;
    resize: none;
  }

  .select2-container--default .select2-selection--single {
    border: 0;
    border-radius: 0;
    height: 40px;
    margin-bottom: 0px;
    padding: 6px;

    .select2-selection__arrow {
      height: 40px;
      top: -1px;
    }

    .select2-selection__placeholder {
      color: #666;
    }
  }

  #shipping_state {
    border: 0;
    appearance: none;
    background: url("./imagenes/down-arrow.png") center 95%;
    background-size: 12px;
    background-repeat: no-repeat;
    background-color: #FFF;
    border-radius: 0;
    padding: 10px;
    color: #666;
  }

  #order_comments_field label {
    display: none;
  }

  .titulocheckout {
    font-size: 16px;
    letter-spacing: 0;
    margin: 20px 0px 0px 0px;
  }
}

.woocommerce-page #order_review {
  width: 100%;
  background-color: #FFF;
  padding: 20px 20px;
  color: #000;

  table.shop_table {
    border: 0;
    border-radius: 0;
    margin: 10px 0px
  }

  h3 {
    font-family: $poppins;
    font-size: 14px;
    color: $turquesa_base;
    margin-bottom: 4px;
  }

  th {
    font-weight: 600;
    padding-left: 0;
    color: #28474f !important;
    font-size: 13px !important;
    line-height: 16px;
    text-transform: uppercase;
  }

  tr.recurring-totals th {
    padding-top: 35px;
    font-size: 13px !important;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  td {
    text-align: right;
    padding-right: 0;
    font-size: 12px;
    color: $turquesa_base;
    font-weight: lighter;

    span {
      font-weight: 600;
      font-size: 13px;
      color: $turquesa_base;

    }

    small {
      color: #777;
    }
  }

  tr:not([class]) {
    display: none;
  }

  #payment {
    background-color: transparent;
    border-radius: 0;

    ul.payment_methods {
      border-bottom: 0;
      padding: 0;
      margin: 0px 0px 20px 0px;

      label {
        letter-spacing: 0px;
        font-weight: 500 !important;
        font-size: 14px !important;
        color: $turquesa_base !important;
      }

      li.payment_method_transbank_webpay_plus_rest label img {
        display: none
      }

      li.payment_method_transbank_oneclick_mall_rest label img {
        display: none;
      }

      li {
        display: inline-block;
        padding: 0px 0px 10px 0px;

        label {
          font-size: 12px;
          text-transform: none;
          padding-left: 5px;
          display: inline;
          font-weight: normal;
          color: #666;
        }

        input {
          display: inline
        }
      }

      .payment_box {
        p {
          font-size: 12px;
        }
      }

      .wc-saved-payment-methods {
        padding-top: 15px;

        li {
          padding: 0px
        }

        input {
          margin: 0px !important
        }

        label {
          font-size: 13px !important;
          letter-spacing: 0px !important;
        }
      }

      .woocommerce-SavedPaymentMethods-saveNew {
        strong {
          line-height: 1.3;
          top: -5px;
          position: relative;
          font-size: 11.5px;
          font-weight: 500
        }

      }
    }

    .vermas {
      background-color: transparent !important;
      float: none;
      width: 100%;
      border-radius: 0;
      margin-top: 0 !important;
    }
  }

  .contenedor-tienda {
    padding: 0 !important;
    background-color: #FFF !important;
    ;
  }
}

.woocommerce-page {

  .select2-container--default .select2-results__option--highlighted[aria-selected],
  .select2-container--default .select2-results__option--highlighted[data-selected] {
    background-color: $dorado !important;
  }

  .select2-container--open .select2-dropdown--below {
    border: 0;
  }

  .select2-search--dropdown {
    padding: 4px 10px;
  }

  .select2-results__option {
    padding: 6px 10px;
  }
}

.woocommerce form .form-row label,
.woocommerce-page form .form-row label {
  display: none !important;
}

.formulariodelogin {
  display: block;
  margin: auto;
}

.woocommerce-form-login-toggle {
  p {
    text-align: left;
  }

  h2 {
    text-align: left;
    margin-bottom: 0px;
    font-size: 22px;
    font-family: $poppins;
    font-weight: bold;
    color: #28474f;

    a {

      text-decoration: underline !important;
      margin-left: 10px;
      color: $turquesa_base;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .woocommerce-info {
    &:before {
      display: none;
    }

    a {
      color: $turquesa_base;
      font-weight: bold;
      text-decoration: underline !important;
    }
  }
}

body.woocommerce-checkout {
  .button-social-login {
    font-size: 85%
  }

  .woocommerce-form-login {
    .vermas {
      margin: 0 0 30px !important;
    }

    .mo-openid-app-icons p {
      color: $turquesa_base !important;
      font-weight: bold;
    }
  }

  #billing_country_field {
    display: none;
  }
}

.recurring-totals th {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #444 !important;
}

.pages {
  display: none;
}

.woocommerce-checkout-payment .container {
  padding: 0px !important
}

#add_payment_method #payment div.form-row,
.woocommerce-cart #payment div.form-row,
.woocommerce-checkout #payment div.form-row {
  padding: 0px !important
}

.woocommerce table.shop_table th {
  padding: 10px 10px 10px 0px !important
}

.woocommerce-form-login-toggle {
  margin-bottom: 20px;

  p {
    font-size: 13px;
    margin-top: 4px;
    font-weight: normal;
    margin-bottom: 0px;
  }
}

.woocommerce-checkout {
  .woocommerce-form-login {
    margin-top: 0px !important;
  }

  h2.titulo-seccion1 {
    font-weight: bold;
    color: #28474f
  }

  .subtitulo-ppal {
    font-weight: 600;
  }
}

#woo_slg_social_login {
  margin: 50px 0px 0px 0px;

  legend {
    font-weight: 600;
    padding-bottom: 10px;
    color: #28474f
  }
}

body .formulariodecheckout {
  .woo-slg-social-container {
    margin: 0px !important
  }

  #woo_slg_social_login {
    margin: 0px !important;
  }

  legend {
    margin-bottom: 0px !important;
  }
}

form.checkout input,
form.checkout textarea {
  margin: 0px 0px 10px 0px !important
}

#checkout_iniciar {
  text-align: left;
  margin-bottom: 50px;

  .wc-social-login {
    text-align: left
  }
}

#checkout_social {
  .wc-social-login {
    text-align: left;
    margin: 6px 0px 30px 0px;
  }

  p.comprapersonalizada {
    font-size: 13 px;
    margin-top: 4px;
  }
}


.woocommerce-checkout form.checkout #shipping_state {
  background-position: right 4% center !important;
}

form.checkout #shipping_state {
  margin: 0px !important
}

#shipping_country_field {
  position: relative;
  top: -12px;
  height: 30px;
  left: 4px;
}

.logged-in.woocommerce-checkout #checkout_social {
  padding-bottom: 30px
}


.woocommerce-checkout .google_form h3,
.woocommerce-checkout .google_form h2 {
  font-family: "Poppins", sans-serif;
  font-size: 13px !important
}


#custom-handle {
  font-family: "Poppins", sans-serif;
  font-size: 15px
}

.woocommerce-checkout .membrana-shipping-methods {
  label {
    text-transform: none !important;
    letter-spacing: 0.3px;
    font-weight: 400 !important
  }

  @media (max-width: 575px) {
    .selector-wrapper {
      margin-bottom: 6px;

      .membrana-shipping-pickup,
      .membrana-shipping-address {
        margin: 0px 6px 0px 0px !important;
        padding: 0px !important;
        top: 3px !important;
        position: relative !important;

      }

      label {
        margin: 0px !important;
        padding: 0px !important
      }

    }

  }
}

.shipping_address {
  .description {
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 4px
  }
}

.woocommerce-cart {

  .recurring-totals,
  .recurring-total {
    display: none
  }
}

.woocommerce-checkout {

  .recurring-totals,
  .shipping.recurring-total,
  .cart-subtotal.recurring-total {
    display: none
  }

  .first-payment-date {
    small {
      font-size: 11px
    }
  }

}




.google_form {
  @media (max-width: 575px) {
    margin-top: 10px
  }

}