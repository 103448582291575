.woocommerce-order-received {

  .woocommerce-columns--addresses {
    display: none !important;
  }

  .woocommerce-order {
    max-width: 1000px;

    th,
    .woocommerce-Price-amount.amount {
      color: #1b3035 !important;
    }
  }

  .woocommerce-order-details {
    background-color: #FFF;
    padding: 30px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    @media (max-width: 575px) { 
      padding: 20px;

    }

    .woocommerce-order-details__title {
      font-family: $poppins;
      font-size: 16px;
      text-transform: uppercase;
      color: $turquesa_base;
    }
  }

  .woocommerce table.shop_table {
    border: 0;

    tr th:first-child {
      padding-left: 0;
    }

    tr td:first-child a,
    tr td:first-child strong.product-quantity {
      font-weight: lighter !important;
    }
  }
}