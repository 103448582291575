.woocommerce-account:not(.logged-in) {

  #header,
  #footer {
    display: none;
  }

  .contenedor-mitad {
    min-height: 100vh;
    padding: 20vh 40px 40px;

    .titulo-seccion:first-child {
      margin-bottom: 0;
    }

    .nav-pills {
      justify-content: center;
      display: flex;
    }

    .nav-pills li {
      position: relative;

      a {
        padding: 0;
        color: #487b86;
        font-size: 32px !important;
      }

      &:first-child:after {
        content: "/";
        display: inline;
        color: #487b86;
        position: absolute;
        top: 0;
        font-size: 34px;
        right: -25px;
        display: none;
      }
    }

    .nav-pills .nav-link.active {
      background-color: transparent;
      color: #ffffff !important;
      font-weight: bold;
      text-align: center;

      &:after {
        content: "";
        width: 100%;
        height: 2px;
        background: #FFF;
        display: block;
        position: relative;
        bottom: 0px;
      }
    }

    #bajada-registro {
      color: #fff;
      margin-bottom: 0;
      font-size: 17px;
    }

    form {
      max-width: 310px;
      border: 0 !important;
      padding: 0px !important;
      margin: 0 auto !important;

      input {
        padding: 10px;
      }

      button.button {
        width: 100%;
        background-color: transparent;
        border: 2px solid #FFF;
        border-radius: 0;
        color: #FFF;
      }

      .lost_password {
        max-width: 310px;
        margin-bottom: 0;

        label {
          color: #FFF;
          text-transform: none;
          font-weight: 500;
          letter-spacing: 0.6px;
          font-size: 11px;
          margin-bottom: 0;

          input {
            margin-bottom: 0 !important;
          }
        }

        a,
        a:hover {
          color: #fff;
          text-decoration: underline !important;
          font-size: 11px;
          float: right;
          padding-top: 3px;
        }
      }

      .mini-texto {
        color: #FFF;
        font-size: 11.4px;
        font-weight: normal;
        position: relative;
        top: -20px;
      }
    }

    #profile2 {
      padding-top: 35px;
    }
  }

  .contenedor-login {
    background-color: $turquesa_base;
  }

  #volver {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.6px;
    position: absolute;
    bottom: 30px;
    left: 40px;

    span {
      margin-right: 10px;
      font-size: 18px;
      font-weight: bold;
      position: relative;
      top: 3px;
    }
  }
}

.woocommerce-lost-password {

  .woocommerce-message,
  .woocommerce-info,
  .woocommerce-error {
    position: absolute !important;
    top: -70px !important;
  }

  .titulo-seccion {
    max-width: none;
    color: #fff !important;
    margin-bottom: 15px !important;
  }

  .bajada-titulo {
    color: #fff !important;
    text-align: center;
    margin-bottom: 30px !important;
  }

  form.lost_reset_password {
    .form-row-first {
      width: 100%;
    }

    input {
      margin: 0 auto 20px !important;
      max-width: 320px;
      padding: 10px;
    }

    button.vermas {
      margin: 0 auto;
      width: 320px;
      height: auto;
    }

    button.woocommerce-Button.vermas {
      width: 100% !important;
      background-color: transparent !important;
      border: 2px solid #FFF !important;
      border-radius: 0 !important;
      color: #FFF !important;
      margin: 0px !important;
    }
  }

  .woocommerce-error {
    z-index: 2;
  }

}

.woocommerce-form-login {
  input {
    padding: 10px;
    margin-bottom: 0px !important;
    border: 0;
  }

  .lost_password {
    a {
      color: #666;
      font-size: 13px;
      text-decoration: underline !important;
    }

    span {
      font-family: $poppins;
      font-weight: lighter;
      letter-spacing: 0;
    }
  }
}

#logo-login {
  top: 34px;
  left: 50px;
  max-width: 140px;
  position: relative
}

.page-id-8 .mo-openid-app-icons {
  position: relative;
  top: 40px
}

.page-id-8 .mo-openid-app-icons p {
  margin-bottom: 10px !important
}

.woo-slg-social-container legend {
  margin: 0 0 10px !important
}

.woocommerce-form-login .woocommerce-Button {
  margin-top: 20px !important;
}

input {
  font-size: 13px;
  border: none
}