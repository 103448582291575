@media (min-width:1560px) {
  #categorias-overlay .box__img--original {
    right: 320px;
    max-height: calc(100vh - 150px) !important;
    margin-top: calc((100vh - 150px) / -2) !important;
  }

  #categorias-overlay .box__text {
    right: 175px;
  }


}

@media (min-width: 1460px) {
  .container {
    max-width: 1400px;
  }
}

@media (max-width: 1459px) {

  .tax-product_cat .woocommerce-breadcrumb,
  .post-type-archive .woocommerce-breadcrumb {
    padding-left: 116px !important;
  }

  body.woocommerce-page.single-product .texto-switch {
    bottom: -1px;
  }

  .woocommerce-cart .coupon {
    #coupon_code {
      padding: .618em 0;
    }

    button {
      position: absolute !important;
      right: 25px;
      top: 17px;
    }
  }

  .woocommerce-cart .cart td {
    .bootstrap-switch {
      left: 0;
    }

    .texto-switch {
      width: auto;
      padding-right: 0;
    }
  }

  .woocommerce-cart.woocommerce-page .texto-switch .tooltip-wrap .tooltip {

    right: -53px;

  }

  .shopping .product .back-blanco img {
    max-width: 100%;
  }

  .shopping .product {
    padding: 0 7px 14px 7px !important
  }

  .shopping .product .woocommerce-loop-product__title {
    font-size: 13px
  }

  .woocommerce-cart .coupon button {
    right: 10px;
    top: -3px;

  }

  html .woocommerce-cart .woocommerce-cart-form .product-name a {
    width: 150px
  }
}

@media (max-width:1360px) {
  form.checkout textarea {
    max-width: none;
  }

  #puntos-retiro #asl-storelocator.asl-p-cont.asl-bg-0 .col-xs-12.search_filter .asl-store-search {
    max-width: 292px;
  }

  #puntos-retiro #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-panel {
    padding-right: 30px !important;
  }

  #puntos-retiro #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-panel .texto-categoria,
  #asl-storelocator.asl-p-cont p,
  #puntos-retiro .tienda_contenedor p,
  #puntos-retiro .tienda_contenedor li {
    font-size: 13px;
    line-height: 23px !important;
  }

  #puntos-retiro #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-panel .p-title {
    font-weight: bold;
  }

  #puntos-retiro .tienda_contenedor {
    right: -30px;
    top: -210px;

    p {
      hyphens: auto;
    }
  }

  body.home {
    .modulo.grid__item-content {
      padding: 0 50px;

      .texto-categoria {
        max-width: none;
      }

      .vermas {
        margin-top: 30px !important;
      }
    }

    #grid-efecto p {
      font-size: 14.6px;
      line-height: 25px;
    }
  }

  #header.header-home #logo img,
  #header.header-home #header-wrap #logo img,
  #categorias-overlay #categorias-carrusel .oc-item .logo-overlaycat {
    max-width: 130px;
  }

  #slider.banner-ppal .slider-caption {
    max-width: 430px;

    h2 {
      letter-spacing: 4px;
      line-height: 59px;

      .color-blanco {
        font-size: 50px;
      }
    }
  }

  #categorias-overlay {
    .box__text .box__text-inner span {
      font-size: 75px;
    }

    .box__title-inner,
    .box__title-inner:hover {
      font-size: 44px;
      letter-spacing: 2.5px;
    }

    .box__img--original {
      right: 140px;
    }
  }

  .woocommerce-MyAccount-content {
    table.my_account_orders .button {
      margin: 7px 5px !important;
    }
  }

  .contenedor-info {
    padding-left: 50px !important;
    padding-right: 50px !important
  }

  body.page-template-ser-jokers h5.titulo-seccion {
    font-size: 15px !important
  }

  body.page-template-ser-jokers .vermas {
    font-size: 12px !important;
    padding: 10px !important
  }

  .fix60 #content {
    width: 55%
  }

  .fix60 #slider {
    width: 45%;
  }

  .fix260 #content {
    width: 55%
  }

  .fix260 #slider {
    width: 45%;
  }


}

.selectores br {
  display: none;
}

@media (min-width:992px) and (max-width: 1199px) {

  #slider.banner-ppal .slider-caption {
    h2 {
      letter-spacing: 2px;
      line-height: 56px;

      .color-blanco {
        font-size: 46px;
      }
    }
  }

  .portfolio.portfolio-5 .portfolio-item {
    width: 25%;
  }

  .woocommerce-cart .cart td .texto-switch {
    font-size: 12px;
    padding: 0;
  }

  .woocommerce-cart .woocommerce .cart-collaterals .cart_totals,
  .woocommerce-cart.woocommerce-page .cart-collaterals .cart_totals {
    width: 100%;
  }

  .woocommerce-cart .coupon #coupon_code {
    width: 83%;
  }

  #puntos-retiro .tienda_contenedor {
    right: 0;
    top: 0;

    p {
      hyphens: none;
    }
  }

  body h3.titulo-seccion,
  body #content-mitad h3.titulo-seccion,
  #puntos-retiro .titulo-seccion {
    font-size: 30px !important;
    line-height: 37px !important;
  }

  #puntos-retiro #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-panel .texto-categoria,
  #asl-storelocator.asl-p-cont p,
  #puntos-retiro .tienda_contenedor li {
    max-width: 350px;
  }

  #puntos-retiro #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-panel #panel.storelocator-panel {
    width: 100% !important;
  }

  body h3.titulo-seccion,
  body #content-mitad h3.titulo-seccion {
    font-size: 30px !important;
    line-height: 37px !important;
  }

  body.home #categorias-overlay {
    #categorias-carrusel .oc-item .logo-overlaycat {
      max-width: 120px;
    }

    .modulo .texto-categoria {
      max-width: 340px;
    }

    .box__title-inner {
      font-size: 38px;
      letter-spacing: 1.5px;
      line-height: 44px;
    }

    .owl-carousel .owl-nav [class*="owl-"] {
      margin-right: 0;
    }

    .box__text {
      right: -38px;

      .box__text-inner span {
        font-size: 70px;
      }
    }

    .box__img--original {
      right: 80px;
      max-height: calc(100vh - 200px) !important;
      top: 50%;
      margin-top: calc((100vh - 200px) / -2) !important;
    }
  }

  .tax-product_cat .woocommerce-breadcrumb,
  .post-type-archive .woocommerce-breadcrumb {
    padding-left: 92px !important;
  }

  .woocommerce-account .woocommerce-MyAccount-content {
    width: 69% !important;
  }

  #logo-login {
    max-width: 130px;
  }

  .woocommerce-product-details__short-description {
    padding-right: 70px !important;
  }

}

@media (max-width: 1199px) {

  .contenedor-info {
    padding-left: 40px !important;
    padding-right: 40px !important
  }

  body.page-template-ser-jokers .vermas {
    top: 0px;
    margin: 0px 0px 40px 0px !important;
    float: left
  }

  #content-mitad .titulo-seccion,
  .woocommerce-cart .titulo-seccion {
    margin-bottom: 30px
  }

  body.page-template-ser-jokers .table {
    margin-bottom: 30px !important;
  }

  #content-info {
    padding: 0px 40px
  }

  .pregunta {
    font-size: 15px !important
  }

  .page-template-preguntas #content-mitad .titulo-seccion {
    margin-bottom: 40px;
  }

  #content-mitad p {
    font-size: 12.4px
  }

  .fix60 #content {
    width: 60%
  }

  .fix60 #slider {
    width: 40%;
  }

  .woocommerce-account:not(.logged-in) .contenedor-mitad .nav-pills li a {
    font-size: 30px !important
  }

  .postcontent .product:nth-of-type(4n+1) {
    clear: none;
  }

  .postcontent .product:nth-of-type(3n+1) {
    clear: both;
  }

  #banner-shop .texto-inside span {
    font-size: 24px
  }

  .box__title-inner {
    font-size: 24px
  }

  .modulo .titulo-seccion {
    font-size: 28px
  }

  #modal-contacto .divcenter {
    max-width: 900px !important;

    .feature-box.fbox-center {
      padding: 100px 50px !important;
    }
  }

  #single-producto {
    .summary .price {
      padding-bottom: 20px;
    }

    .serjokerinterno {
      display: block;
    }

    .reviews {
      float: none;
      margin-top: 10px;
      display: block;
    }
  }

  #modal-joker {
    .divcenter {
      max-width: 550px !important;
    }

    .feature-box {
      padding: 50px 80px;
    }
  }

  #footer .contenedormailchimp input[type="email"],
  #footer2 .contenedormailchimp input[type="email"] {
    width: 70% !important
  }

  .woocommerce-cart {
    .cart_totals {
      top: 0;
    }

    table.cart td.product-subtotal {
      text-align: center;
    }

    .cart td .bootstrap-switch {
      float: none
    }

    .cart td .texto-switch {
      width: 100%;
      text-align: center;
    }

    .tooltip {
      display: none !important
    }
  }

}


@media (min-width: 992px) {

  body.home #primary-menu>ul {
    display: none !important
  }


  body.home header.sticky-header #primary-menu ul {
    display: block !important
  }
}





@media (max-width: 991px) {
  form.checkout input[type="radio"]:not(.input-radio) {
    margin-left: unset !important;
  }

  .selectores br {
    display: block;
  }

  .woocommerce-checkout .modal-body {
    padding: 30px 30px !important;
  }

  .woocommerce-cart {
    #shipping-alerta {
      line-height: 18px;
      margin-bottom: 30px;
    }

    .ultramini {
      top: -2px;
    }

    .ultramini.ultraminiv1 {
      top: -5px;
    }
  }

  .subtitulo-ppal {
    font-size: 22px;
  }

  #slider.banner-ppal .slider-caption {
    margin-top: 0px;

    h2 {
      letter-spacing: 2px;
      line-height: 50px;

      span {
        display: block;
      }

      .color-blanco {
        font-size: 40px;
      }

      .color-dorado {
        font-size: 28px;
      }
    }

    p {
      font-size: 15px;
    }
  }


  .home {
    .imagen-modulo-home {
      height: 380px;
    }

    .modulo {
      padding: 80px 60px !important;

      a.condiciones {
        display: block;
      }
    }
  }

  .modulo .texto-categoria {
    max-width: 420px
  }

  .modulo .titulo-seccion {
    max-width: 100%
  }

  .toggle {
    display: block;
    margin-bottom: 0;

    .togglec {
      width: 100%;
    }
  }

  .sidebar,
  .tax-product_cat .etiqueta-filtros,
  .post-type-archive .etiqueta-filtros {
    display: none;
  }

  body .prdctfltr-widget .prdctfltr_wc .prdctfltr_woocommerce_ordering .prdctfltr_filter {
    width: max-content !important;
    display: inline-block;
    margin-right: 40px;
  }

  body h3.titulo-seccion,
  body #content-mitad h3.titulo-seccion,
  .woocommerce-cart .titulo-seccion {
    font-size: 28px !important;
    line-height: 36px !important;
  }

  #content-mitad {
    h5.titulo-seccion {
      font-size: 16px !important;
    }
  }

  .tax-product_cat .woocommerce-breadcrumb,
  .post-type-archive .woocommerce-breadcrumb {
    width: calc(100% - 184px);
    padding-left: 0 !important;
    text-align: right;
    float: right;
  }

  #header.transparent-header {
    border-bottom: 0 !important;

    #header-wrap {
      border-bottom: 0 !important;
    }


    #primary-menu {
      padding: 0 10px;

      >ul>li {
        .mega-menu-content {
          left: 0 !important;
          top: 0;

          .mega-menu-column {
            padding: 0 12px;
            position: relative;
            top: -8px !important;
          }

          li a {
            color: #fff;
            text-transform: uppercase;
            font-family: $poppins;
            font-weight: bold;
            letter-spacing: 1px;
          }
        }
      }

      >ul #menu-shishas .mega-menu-content ul.mega-menu-column li:last-child {
        padding-bottom: 1px;
      }

      .menulogin.clearfix li {
        border: 0 !important;

        a {
          padding: 0 !important;
          margin-bottom: 6px !important;
        }
      }
    }

    #header-wrap #top-session,
    &:not(.header-home) #header-wrap #top-session {
      top: 5px;
      position: absolute !important;
      right: 81px;
      margin-right: 0 !important;

    }

    &.header-home {
      background-color: $turquesa;

      #header-wrap {
        background-color: $turquesa;
      }

      #logo {
        &.logo-sticky {
          display: block !important;
        }

        &.logo-banner {
          display: none;
        }

        img {
          position: relative !important;
          max-width: 96px !important;
          top: 16px !important;
          left: auto !important;
        }
      }

      #top-cart {
        padding-right: 15px !important;
        right: 35px;
      }
    }

    &:not(.header-home) {
      #header-wrap #top-cart {
        padding-right: 15px;
      }

      #top-cart {
        padding-right: 15px !important;
        right: 35px;
      }
    }

    #top-search a {
      right: 120px;
    }
  }

  #header.transparent-header+#slider {
    top: -1px !important;
    margin-bottom: -1px !important;
  }

  body.page-template-ser-jokers,
  body.page-template-condiciones-entrega,
  body.page-template-preguntas,
  body.page-template-gracias1,
  body.page-template-gracias {

    #slider,
    #slider .fslider,
    #slider .flexslider,
    #slider .slide {
      max-height: 300px !important;
      width: 100% !important;
    }

    #content {
      width: 100%;

      .contenedor-info {
        .vermas {
          margin-top: 0 !important;
          margin-bottom: 30px;
        }
      }
    }
  }

  #footer2,
  #footer {
    .columna2.elementos-centro {
      justify-content: flex-start;
    }
  }

  body #header.transparent-header {
    background-color: $turquesa_base;
  }

  #puntos-retiro #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-panel .texto-categoria,
  #asl-storelocator.asl-p-cont p,
  #puntos-retiro .tienda_contenedor li,
  #puntos-retiro #asl-storelocator.asl-p-cont.asl-bg-0 .col-xs-12.search_filter .asl-store-search {
    max-width: 460px;
  }

  #puntos-retiro .tienda_contenedor {
    right: 0;
    top: 0;

    p {
      hyphens: none;
    }
  }

  #asl-storelocator.asl-p-cont.asl-bg-0.no-asl-filters #panel {
    height: auto !important;
  }

  #puntos-retiro #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-panel #panel.storelocator-panel {
    width: 100% !important;
  }

  #puntos-retiro #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-panel {
    #panel.storelocator-panel {
      margin-bottom: 30px;
    }

    h5.titulo-seccion {
      font-size: 14px !important;
      max-width: 300px;
    }

    .tienda_contenedor {
      h3.titulo-seccion {
        font-size: 17px !important;
        line-height: 26px !important;
      }

      h5.titulo-seccion {
        font-size: 14px !important;
        line-height: 20px !important;
      }
    }
  }

  body h3.titulo-seccion,
  body #content-mitad h3.titulo-seccion,
  #puntos-retiro .titulo-seccion {
    font-size: 28px !important;
    line-height: 36px !important;
  }


  #single-producto {
    .product_title.entry-title {
      font-size: 28px;
      line-height: 34px;
    }

    .contenedor-info .tabla-detalles {
      margin: 30px 0;
    }

    .contenedor-info {
      padding: 0px 15px 0 15px !important;
    }
  }

  .woocommerce-product-details__short-description {
    padding-right: 30px !important;
  }

  #full-bannerbajada {
    display: flex;
    justify-content: center;
    height: 280px;

    .texto-inside {
      position: relative;
      line-height: 33px;

      span {
        font-size: 32px;
      }
    }
  }



  body.woocommerce-page.single-product .texto-switch {
    bottom: 8px;
  }

  .woocommerce-cart {

    .woocommerce .cart-collaterals .cart_totals,
    &.woocommerce-page .cart-collaterals .cart_totals {
      max-width: 400px;
      float: left;
    }

    .coupon button {
      right: auto;
      left: 364px !important;
    }
  }

  .woocommerce-account {
    .woocommerce-MyAccount-navigation {
      width: 100% !important;
    }

    &.logged-in nav ul {
      border-right: 0;
      padding-right: 0;
      max-width: 160px;
      text-align: center;
      margin: 0 auto 30px;
    }

    .woocommerce-MyAccount-content {
      width: 100% !important;
      margin-top: 40px;

      form .form-row-first,
      form .form-row-last {
        width: 49%;
      }
    }

    table.my_account_orders td {
      padding: 10px 8px !important;
    }
  }

  html .woocommerce table.shop_table_responsive tr td.order-actions,
  html .woocommerce-page table.shop_table_responsive tr td.order-actions {
    text-align: right !important;
  }


  #top-cart .top-cart-item-quantity {
    padding-left: 15px;
    font-size: 12.5px;
  }

  .style-msg.successmsg .sb-msg,
  .woocommerce-form-login-toggle .woocommerce-info .sb-msg {
    padding: 15px 55px;

    i {
      font-size: 24px;
      left: 20px;
      top: 7px;
    }
  }

  .style-msg .close {
    right: 0;
    top: 12px;
  }

  .woocommerce-account:not(.logged-in) .contenedor-mitad {
    #logo-login {
      max-width: 120px;
      left: 40px;
      top: 20px;
    }

    .titulo-seccion:first-child {
      margin-bottom: 0 !important;
    }

    .nav-pills li a {
      font-size: 26px !important;
    }
  }

  #categorias-overlay {
    display: none
  }

  #header.header-home #logo img,
  #header.header-home #header-wrap #logo img,
  #categorias-overlay #categorias-carrusel .oc-item .logo-overlaycat {
    max-width: 100px !important
  }

  #footer .contenedor-derechos,
  #footer2 .contenedor-derechos {
    font-size: 10.4px
  }

  #top-cart p.woocommerce-mini-cart__empty-message {
    font-size: 12px
  }

  .woocommerce .cart-collaterals,
  .woocommerce-page .cart-collaterals {
    margin-bottom: 30px;
  }

  .woocommerce-cart .actualizarcarro {
    text-align: left;
    padding: 0;
    margin-top: 15px;
  }

  .woocommerce-cart #interes-carrusel {
    padding: 0;
  }

  #top-cart .mensajepersonalizado p {
    font-size: 10px
  }

  #top-cart .top-cart-content {
    width: 320px;
    left: -310px
  }

  #content #content-mitad.content-wrap {
    padding: 60px 0px
  }

  #content-info {
    padding: 0px 15px
  }

  #header #primary-menu>ul .mega-menu-content {
    border: none !important
  }

  .contenedor-info {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  #content-mitad,
  #terminos #content-info {
    min-height: auto !important
  }

  .postcontent.shopping {
    left: 0px
  }

  .tax-product_cat,
  .post-type-archive {
    .woocommerce-breadcrumb {
      width: 100% !important;
      margin: 0px !important;
      padding: 0px !important;
      float: left;
      text-align: left
    }

    .woocommerce-products-header {
      float: left;
      text-align: left;
      padding: 0px 5px 20px 5px;
      width: 100%;

      &+.postcontent {
        float: left !important;
      }
    }
  }

  #banner-shop {
    display: none
  }

  .tax-product_cat #content .content-wrap,
  .post-type-archive #content .content-wrap {
    padding-top: 60px !important
  }

  #modal-contacto .divcenter {
    max-width: 700px !important;

    .feature-box.fbox-center {
      padding: 60px 30px !important;
    }

    .contenedor-formulario {
      padding-top: 0;

      p {
        margin-top: 0;
      }
    }

    .gform_button {
      float: left;
    }
  }

  #puntos-retiro #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-map {
    height: 500px !important;
  }

  #puntos-retiro .vermas {
    bottom: 550px;
    left: 70px;
  }

  #modal-joker {
    .divcenter {
      max-width: 500px !important;
    }

    .feature-box {
      padding: 50px;
    }

    .titulo-seccion {
      font-size: 28px;
    }
  }

  #footer .contenedormailchimp input[type="email"],
  #footer2 .contenedormailchimp input[type="email"] {
    width: 50% !important
  }

  body.primary-menu-open #primary-menu ul {
    display: block !important
  }

  #primary-menu ul {
    display: none !important
  }

  body #primary-menu .menulogin ul {
    display: block !important
  }

  body.single-product .texto-switch .tooltip-wrap .tooltip {
    left: 0px;
  }

  .woocommerce-cart .woocommerce .cart-collaterals .cart_totals,
  .woocommerce-cart.woocommerce-page .cart-collaterals .cart_totals,
  .woocommerce-cart .coupon #coupon_code {
    width: 100%;
    max-width: 100%
  }

  .woocommerce-cart .coupon button {
    right: 0px !important;
    left: auto !important
  }

  html .woocommerce-cart .woocommerce-cart-form .product-name a {
    width: auto !important
  }

  body.woocommerce-cart .togglet {
    top: 0px !important;
    font-size: 12px !important
  }

  body.woocommerce-cart .toggle {
    margin: 0 0 16px 0
  }

  .woocommerce-checkout #order_review {
    margin-top: 40px
  }


}




@media (max-width: 768px) {
  .woocommerce-cart .actualizarcarro {
    text-align: center !important;
  }

  .woocommerce-cart .woocommerce .cart-collaterals .cart_totals,
  .woocommerce-cart.woocommerce-page .cart-collaterals .cart_totals {
    max-width: unset !important;
    width: 100% !important;
  }

  .woocommerce-cart .titulo-seccion {
    font-size: 34px;
    margin-bottom: 35px;
    letter-spacing: 1.5px;
  }

  .woocommerce-cart .shop_table.shop_table_responsive.cart tr {
    &:nth-child(2n) td {
      background-color: transparent;
    }

    &:first-child td {
      border-top: 1px solid #E5E5E5;
      ;
    }

    td {
      padding: 10px !important;

      &.product-quantity {
        margin-bottom: 30px;

        .quantity {
          float: right;

          input {
            width: 70px;
            height: 34px;
            position: relative;
            top: -2px;
            border: 1px solid #e5e5e5 !important;
          }
        }
      }

      &.product-price {
        &::before {
          font-size: 14px;
        }
      }

      &.product-subtotal {
        margin-bottom: 33px;

        .texto-switch {
          right: 15px;
        }
      }
    }
  }

  .woocommerce #content table.cart .product-thumbnail,
  .woocommerce table.cart .product-thumbnail,
  .woocommerce-page #content table.cart .product-thumbnail,
  .woocommerce-page table.cart .product-thumbnail {
    display: block !important;
    text-align: left !important;

    &:before {
      display: none;
    }
  }

  .bootstrap-switch .bootstrap-switch-handle-on,
  .bootstrap-switch .bootstrap-switch-handle-off,
  .bootstrap-switch .bootstrap-switch-label {
    line-height: 14px !important;
    padding: 6px !important;
    font-size: 10.5px !important;
  }

  #footer .contenedormailchimp input[type="email"],
  #footer2 .contenedormailchimp input[type="email"] {
    width: 80% !important
  }


  .woocommerce table.shop_table_responsive tr td::before,
  .woocommerce-page table.shop_table_responsive tr td::before {
    font-weight: 600 !important;
    padding-left: 0;
    color: #28474f !important;
    font-size: 12px !important;
    line-height: 16px;
    position: relative;
    top: 4px;
  }

  .woocommerce #content table.cart td.actions {
    padding: 0 !important;
    border: 0 !important;

    #update-car {
      display: none;
    }
  }

  .woocommerce-cart .shop_table.shop_table_responsive.cart tr td.product-subtotal {
    margin-bottom: 0px;

    .texto-switch {
      float: none;
      right: 0;
      margin-left: 15px;
      top: 0;
      width: auto;
    }
  }

  .woocommerce table.shop_table_responsive tr:nth-child(2n) td,
  .woocommerce-page table.shop_table_responsive tr:nth-child(2n) td {
    background-color: transparent !important;
  }

  .woocommerce table.shop_table td {
    padding-left: 0 !important;
  }

  .product-quantity ::before {
    display: none
  }


}

@media (max-width: 767px) {

  .css3-spinner .logoloading {
    width: 130px;
    margin-top: -240px;
  }

  .vermas {
    padding: 7px 13px !important;
  }

  .home .swiper_wrapper:not(.force-full-screen),
  .home .swiper_wrapper:not(.force-full-screen):not(.canvas-slider-grid) .swiper-slide {
    height: 600px !important;
  }

  .home {
    .imagen-modulo-home {
      height: 350px;
    }
  }

  #slider.banner-ppal .slider-caption {
    h2 {
      .color-blanco {
        font-size: 41px;
      }

      .color-dorado {
        font-size: 35px;
      }
    }

    p {
      font-size: 14px;
      max-width: 370px;
      line-height: 26px;
    }

    .vermas {
      font-size: 13.5px !important;
    }
  }



  body h3.titulo-seccion,
  body #content-mitad h3.titulo-seccion,
  .woocommerce-cart .titulo-seccion {
    font-size: 26px !important;
    line-height: 30px !important;
  }

  .shopping .product {
    width: 100%;
    float: left;
  }

  #single-producto {
    .product_title.entry-title {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 24px
    }

    .contenedor-info {
      padding-left: 15px;
    }

    .woocommerce-product-details__short-description>p {
      font-size: 14px;
    }

    .woocommerce-product-gallery__wrapper {
      max-width: 250px;
    }
  }

  body.woocommerce-page.single-product .texto-switch {
    bottom: 5px;
  }

  #full-bannerbajada {
    height: 270px;

    .texto-inside span {
      font-size: 30px;
    }
  }

  #banner-shop {
    line-height: 26px;

    .texto-inside span {
      font-size: 26px;
    }
  }

  body.woocommerce-page .woocommerce-message .button,
  body.woocommerce-page .woocommerce-info .button,
  body.woocommerce-page .woocommerce-error .button {
    position: relative !important;
    right: -20px !important;
    left: auto !important;
    top: auto !important;
  }

  .woocommerce-cart .content-wrap {
    padding-top: 50px !important;
  }

  html .woocommerce-account {
    .woocommerce-MyAccount-navigation {
      width: 100% !important;
    }

    .woocommerce-MyAccount-content {
      width: 100% !important;

    }
  }

  .woocommerce-edit-address .edit.vermas {
    margin-top: 20px !important;
  }

  .woocommerce-page form {
    .form-row {
      margin: 0;

      &.form-row-last,
      &.form-row-first {
        width: 100%;
      }

      .input-text {
        margin-top: 0 !important;
      }
    }

    &.edit-account {
      .vermas {
        margin-top: 0 !important;
      }
    }
  }

  html body.page-template-ser-jokers,
  body.page-template-condiciones-entrega,
  body.page-template-preguntas {
    .table {
      margin-bottom: 30px !important;

      thead th {
        font-size: 14.5px;
      }
    }

    #slider,
    #slider .fslider,
    #slider .flexslider,
    #slider .slide {
      height: 320px !important;
    }
  }

  #content-mitad,
  #terminos {
    .pregunta {
      font-size: 15px;
    }

  }

  #puntos-retiro #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-panel .texto-categoria,
  #asl-storelocator.asl-p-cont p,
  #puntos-retiro .tienda_contenedor li,
  #puntos-retiro #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-panel h5.titulo-seccion,
  #puntos-retiro #asl-storelocator.asl-p-cont.asl-text-1 #panel .panel-cont,
  body #puntos-retiro #asl-storelocator.asl-p-cont.asl-bg-0 .col-xs-12.search_filter .asl-store-search {
    max-width: 430px;
  }

  #puntos-retiro #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-map {
    height: 400px !important;
  }

  .woocommerce-account:not(.logged-in) .contenedor-mitad {
    #logo-login {
      left: 0px;
      margin: 10px auto 140px auto;
      -webkit-box-align: center;
      -webkit-box-pack: center;
      display: -webkit-box;
    }

    form {
      max-width: 390px;
      padding-top: 0px !important;
    }

    .nav-pills li a {
      font-size: 24px !important;
      margin-bottom: 0px !important;

      &.nav-link.active::after {
        bottom: 2px;
      }
    }
  }

  .woocommerce-account:not(.logged-in) .contenedor-mitad {
    padding: 0px 40px !important
  }

  .postcontent .product:nth-of-type(3n+1) {
    clear: none;
  }

  .postcontent .product:nth-of-type(2n+1) {
    clear: both;
  }

  #puntos-retiro .vermas {
    position: absolute;
    bottom: 450px;
    left: 90px;
  }

  #modal-contacto .divcenter {
    .feature-box.fbox-center {
      padding: 60px 20px !important;
    }
  }

  #modal-joker {
    .divcenter {
      max-width: 400px !important;
    }

    .feature-box {
      padding: 30px;
    }

    h5 {
      font-size: 13px;
    }

    .titulo-seccion {
      font-size: 26px;
    }
  }

  .woocommerce-page #order_review {
    width: 100%;
  }

  #single-producto .contenedor-info form.cart {
    margin: 0px
  }

  #single-producto .contenedor-info .tabla-detalles {
    margin: 20px 0;
  }

  .ism_wrap {
    margin-top: 50px !important;
  }

  .single-product .related {
    margin-top: 0px;
  }

  #single-producto .quantity .qty {
    width: 60px;
    font-size: 14px
  }

  table.cart .quantity .qty {
    border: 0 !important;
  }

  .woocommerce-cart .subtitulo-ppal {
    font-size: 20px;
  }

  .woocommerce-checkout .woocommerce form.login p input {
    width: 100% !important
  }

  .woocommerce-cart #shipping-alerta {
    margin-bottom: 40px
  }

  html .woocommerce-cart .woocommerce-cart-form a.remove,
  html .woocommerce-cart .woocommerce-cart-form a.remove:hover {
    margin: 0 auto
  }

  .woocommerce #content table.cart .product-thumbnail,
  .woocommerce table.cart .product-thumbnail,
  .woocommerce-page #content table.cart .product-thumbnail,
  .woocommerce-page table.cart .product-thumbnail {
    text-align: center !important
  }

  body .product-remove {
    border-top: 2px !important
  }



  .woocommerce-cart .shop_table.shop_table_responsive.cart tr td.product-quantity .quantity input {
    width: 60px !important;
    height: 28px !important;
    line-height: normal !important;
  }


  .woocommerce-cart-form tr td::before {
    display: none !important
  }

  .woocommerce-cart .woocommerce .cart-collaterals .cart_totals span,
  .woocommerce-cart.woocommerce-page .cart-collaterals .cart_totals span,
  html .woocommerce-cart .woocommerce-cart-form .product-name a,
  .woocommerce-cart #shipping-alerta,
  body.woocommerce-cart .togglet {
    font-size: 12px;
  }


  .woocommerce-cart .woocommerce .cart-collaterals .cart_totals,
  .woocommerce-cart.woocommerce-page .cart-collaterals .cart_totals {
    border: 14px solid #FFF;
  }

  body.woocommerce-cart #tabla-carrito td.product-remove {
    position: absolute !important;
    right: 4px !important
  }

  body.woocommerce-cart #tabla-carrito td.product-quantity {
    position: absolute !important
  }

  .woocommerce-cart .shop_table.shop_table_responsive.cart tr td.product-name a {
    float: left !important
  }

  body.woocommerce-cart #tabla-carrito td.product-quantity {

    position: absolute;
    margin: 0px !important;
    margin-top: 0px;
    margin-right: 0px;
    right: 0px !important;
    margin-top: -46px !important;
    border: none !important;
    margin-right: 10px !important;

  }

  .woocommerce-cart .shop_table.shop_table_responsive.cart tr td {

    padding: 12px 18px !important;

  }


  body.woocommerce-cart #tabla-carrito td.product-price {
    text-align: left !important
  }



  body.woocommerce-cart #tabla-carrito td.product-subtotal {

    position: absolute;
    margin: 0px !important;
    margin-top: 0px;
    margin-right: 0px;
    right: 0px !important;
    margin-top: -51px !important;
    border: none !important;
    margin-right: 10px !important;

  }

  body.woocommerce-cart .cart td .bootstrap-switch {
    float: right !important;
    margin-left: 10px !important
  }

  body.woocommerce-cart .shop_table.shop_table_responsive.cart tr td.product-subtotal .texto-switch {
    top: 4px !important;
  }

  body.woocommerce-page .bootstrap-switch-on+.texto-switch .tooltip-wrap span {
    font-size: 11px !important
  }

  .woocommerce-cart .woocommerce .cart-collaterals .cart_totals h2,
  .woocommerce-cart.woocommerce-page .cart-collaterals .cart_totals h2 {
    font-size: 13px !important
  }

  #top-cart .top-cart-item-quantity {
    font-size: 12px !important
  }



  .woocommerce-cart .woocommerce .cart-collaterals .cart_totals tr.recurring-totals:not(.order-total) th:first-child,
  .woocommerce-cart.woocommerce-page .cart-collaterals .cart_totals tr.recurring-totals:not(.order-total) th:first-child {
    padding-bottom: 4px !important
  }


  .woocommerce-cart .tipodecompra {
    font-size: 11.6px
  }



}

@media (max-width:767px) and (max-height: 820px) {
  .woocommerce-account:not(.logged-in) .contenedor-mitad {
    min-height: 820px;
  }
}


@media (max-width: 575px) {
  .woocommerce-checkout .modal-header h4 {
    left: 15px;
  }

  #primary-menu-trigger {
    left: 8px !important
  }

  #header.transparent-header #primary-menu {
    padding: 0px
  }

  #header:not(.header-home),
  #header.header-home {
    #header-wrap #logo img {
      max-width: 100px !important;
      top: 18px !important;
    }

    #top-session {
      right: 62px !important;
    }

    #top-search a {
      right: auto;
      left: 58px;
    }
  }

  .top-search-open #header:not(.header-home) #top-search a,
  .top-search-open #header.header-home #top-search a {
    right: 37px;
    left: auto;
  }

  .top-search-open #header:not(.header-home) #top-session,
  .top-search-open #header:not(.header-home) #top-cart,
  .top-search-open #header.header-home #top-session,
  .top-search-open #header.header-home #top-cart,
    {
    display: none
  }

  #top-cart .top-cart-content {
    left: -289px;

    &::after {
      right: 16px;
    }
  }

  #header #top-search form input,
  #header #top-search form input::placeholder {
    font-size: 15px;
  }

  #slider.banner-ppal .slider-caption {
    max-width: 70%;

    h2 {
      line-height: 45px;

      .color-blanco {
        font-size: 36px;
      }

      .color-dorado {
        font-size: 28px;
      }
    }

    .vermas {
      font-size: 13.5px !important;
    }
  }

  .home {
    .imagen-modulo-home {
      height: 300px;
    }
  }


  .modulo .texto-categoria {
    max-width: 100%;
  }

  #content-mitad h5.titulo-seccion {
    font-size: 15px !important;
  }


  #footer2,
  #footer {
    .elementos-centro {
      justify-content: flex-start;
    }

    .columna4 ul li {
      display: inline-block;
      margin: 40px 6px 0 0;
    }
  }

  #footer .contenedor-derechos,
  #footer2 .contenedor-derechos {
    margin-top: 24px;
    font-size: 10px;
    text-align: left !important
  }

  #footer .columna4 ul li a,
  #footer2 .columna4 ul li a {
    font-size: 12px
  }

  #footer ul li a,
  #footer2 ul li a {
    font-size: 11px
  }

  #footer ul li,
  #footer2 ul li {
    margin: 0px 0px 2px 0px
  }

  #footer .columna4 ul li a,
  #footer2 .columna4 ul li a {
    height: 30px;
    width: 30px;
    line-height: 29px !important
  }


  #full-bannerbajada {
    height: 250px;

    .texto-inside span {
      font-size: 26px;
    }
  }

  #banner-shop {
    line-height: 23px;

    .texto-inside span {
      font-size: 23px;
    }
  }

  .woocommerce-cart {
    .shop_table.shop_table_responsive.cart tr td {
      text-align: left;

      &.product-name {
        clear: both;
        content: "";
        display: table;
        width: 100%;

        a {
          float: left;
          max-width: calc(100% - 140px);
          text-align: left;
        }
      }

      &.product-subtotal {
        margin-bottom: 23px;
        position: relative;

        .texto-switch {
          line-height: 10px;
        }
      }
    }

    .woocommerce .cart-collaterals .cart_totals,
    &.woocommerce-page .cart-collaterals .cart_totals {
      width: 100%;
      max-width: none;
    }

    .coupon {
      #coupon_code {
        font-size: 12px;
        max-width: 100%;
      }

      button {
        right: 27px;
        left: auto !important;
      }
    }
  }

  html .woocommerce-account.logged-in {
    #content .content-wrap {
      padding: 60px 0 !important;
    }
  }

  html body.page-template-ser-jokers,
  body.page-template-condiciones-entrega,
  body.page-template-preguntas {
    .table {
      thead th {
        font-size: 12.6px;
      }

      td {
        font-size: 12.6px !important;
      }
    }

    #slider,
    #slider .fslider,
    #slider .flexslider,
    #slider .slide {
      height: 200px !important;
    }
  }

  #content #content-mitad.content-wrap {
    padding: 60px 0px;
  }

  #content-mitad,
  .pregunta {
    font-size: 14.5px !important;
  }

  #termino p {
    font-size: 13.5px;
  }

  #puntos-retiro #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-panel .texto-categoria,
  #asl-storelocator.asl-p-cont p,
  #puntos-retiro .tienda_contenedor li,
  #puntos-retiro #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-panel h5.titulo-seccion,
  #puntos-retiro #asl-storelocator.asl-p-cont.asl-text-1 #panel .panel-cont,
  body #puntos-retiro #asl-storelocator.asl-p-cont.asl-bg-0 .col-xs-12.search_filter .asl-store-search {
    max-width: 100%;
  }

  #puntos-retiro #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-panel {
    padding: 60px 40px 90px !important;
  }

  #puntos-retiro #asl-storelocator.container.no-pad.storelocator-main.asl-p-cont.asl-bg-0 a.vermas {
    max-width: 100%;
  }

  .woocommerce-account:not(.logged-in) .contenedor-mitad {
    form {
      input.input-text {
        font-size: 13px;
        margin-bottom: 5px !important;
      }

      button.button {
        font-size: 12px;
      }

      .lost_password {
        margin-top: 10px;
        max-width: none;

        label {
          display: block;
          width: 100%;
        }

        a {
          display: block;
          float: none;
          margin-top: 10px;

          &:hover {
            float: none;
          }
        }
      }
    }

    .nav-pills li a {
      font-size: 22px !important;

      &.nav-link.active::after {
        bottom: 2px;
      }
    }
  }

  #header.transparent-header.header-home #top-cart,
  #header.transparent-header:not(.header-home) #top-cart {
    right: 20px
  }

  .home .modulo {
    padding: 90px 50px !important;
  }

  #content-mitad p,
  #terminos p {
    font-size: 12px
  }

  #footer2 .col-6,
  #footer .col-6 {
    flex: 100%;
    max-width: 100%
  }

  #esconder {
    display: none
  }

  .woocommerce-account:not(.logged-in) .contenedor-mitad form {
    max-width: 100%;
    margin: 0px !important;
  }

  .woocommerce-account:not(.logged-in) .contenedor-mitad form input.input-text {
    font-size: 12px
  }

  .home .swiper_wrapper:not(.force-full-screen),
  .home .swiper_wrapper:not(.force-full-screen):not(.canvas-slider-grid) .swiper-slide {
    height: 560px !important;
  }

  .modulo .titulo-seccion {
    font-size: 26px
  }

  .modulo .texto-categoria p,
  .modulo .texto-categoria {
    font-size: 13.4px
  }

  .modulo .texto-categoria p {
    max-width: 100%;
  }

  #puntos-retiro .vermas {
    left: 60px;
    max-width: 70%;
  }

  #modal-joker {
    .titulo-seccion {
      font-size: 24px;
    }
  }






  .woocommerce-account:not(.logged-in) .contenedor-mitad {
    min-height: calc(100vh + 90px);

    #logo-login {
      margin: 10px auto 80px auto;
    }
  }

  .iniciar .boot-tabs {
    margin-bottom: 20px !important;
  }

  .woocommerce-account:not(.logged-in) .woocommerce-form-login .woocommerce-Button {
    margin-top: 10px !important;
  }

  body.woocommerce-page .woocommerce-message .button,
  body.woocommerce-page .woocommerce-info .button,
  body.woocommerce-page .woocommerce-error .button {
    display: block;
    width: 100%;
    left: -20px !important;
    right: auto !important;
  }

  form.checkout label {
    font-size: 13.5px;
  }

  .woocommerce-cart .cart-empty {
    font-size: 28px;
    line-height: 33px !important;
  }

  #top-cart .subtotal.clearfix {
    padding: 20px 45px 0 80px;
  }

  #footer .contenedormailchimp input[type="email"],
  #footer2 .contenedormailchimp input[type="email"] {
    width: 70% !important
  }

  #footer .contenedormailchimp input[type="submit"],
  #footer2 .contenedormailchimp input[type="submit"] {
    width: 30% !important;
    font-size: 10px;
    line-height: 31px
  }

  .shopping .product {
    padding: 0 5px 14px 5px !important;
  }

  #footer .columna4 ul li a,
  #footer2 .columna4 ul li a {
    border: 1px solid #fff !important
  }

  #footer .icon-instagram,
  #footer2 .icon-instagram {
    font-size: 10px;
  }

  body.tax-product_cat #content .container {
    padding: 0px 10px !important
  }

  .tax-product_cat .woocommerce-products-header,
  .post-type-archive .woocommerce-products-header {
    padding: 0px 0px 20px 0px
  }

  .prdctfltr_buttons {
    display: none
  }

  .toggle .togglec {
    padding-top: 14px
  }

  body .prdctfltr-widget .prdctfltr_wc .prdctfltr_woocommerce_ordering .prdctfltr_filter {
    margin-bottom: 0px !important
  }

  .shopping .product .woocommerce-loop-product__title {
    font-size: 12px;
    line-height: 16px;
  }

  .shopping .product .price .amount,
  .shopping .product .price .amount:hover,
  .back-blanco .conprecio-joker {
    font-size: 12px;
    line-height: 16px;
  }

  .shopping .product .price {
    font-size: 12px;
  }

  .shopping .product .woocommerce-loop-product__title {
    margin-bottom: 10px
  }

  #single-producto .woocommerce-product-details__short-description>p {
    font-size: 13px
  }

  #single-producto .woocommerce-product-gallery__wrapper {
    max-width: 100%
  }

  body.woocommerce-page.single-product .texto-switch {
    display: inline-grid;
    text-align: left;
    position: relative;
    font-size: 12px;
    padding-left: 0px;
    bottom: 10px;
    padding-right: 0px
  }

  .mensual_wrapper {
    margin-top: 10px;
    margin-bottom: 10px
  }

  #single-producto .summary .price .serjokerinterno a {
    display: block
  }

  .single-product .bootstrap-switch {
    margin-right: 10px;
    margin-bottom: 10px
  }

  .single_add_to_cart_button {
    left: 10px !important;
  }

  #single-producto .contenedor-info .tabla-detalles p {
    font-size: 12px
  }

  .woocommerce-cart .subtitulo-ppal {

    font-size: 19px;

  }

  .wc-social-login.form-row-wide {
    text-align: left
  }

  .woocommerce-cart #shipping-alerta i {
    display: none
  }

  .woocommerce-cart #shipping-alerta {
    padding-left: 0px
  }

  .woocommerce-form-login-toggle h2 a {
    display: block;
    margin-left: 0px
  }


  .gmap_search_wrapper {
    width: 84% !important;

    input {
      font-size: 12px
    }


  }

  .geolocate_search {
    width: 15% !important;
    font-size: 11px !important
  }

  .woocommerce-checkout .google_form h3,
  woocommerce-checkout .google_form p,
  .woocommerce-form-login-toggle p,
  #checkout_social p.comprapersonalizada,
  .woocommerce form .form-row input.input-text,
  .woocommerce form .form-row textarea,
  #side_bar label p {
    font-size: 12px !important
  }

  #custom-handle {
    font-size: 14px
  }

  .woocommerce-checkout .titulo-seccion1,
  .woocommerce-form-login-toggle h2 {
    font-size: 19px
  }

  .woocommerce-form-login-toggle h2 a {
    font-size: 14px
  }

  form.checkout .titulocheckout {
    font-size: 15px
  }

  #checkout_iniciar {
    margin-bottom: 20px
  }

  form.checkout input,
  form.checkout textarea {
    margin: 0px 0px 4px 0px !important
  }
}



@media (max-width: 359px) {
  .shopping .product {
    max-width: none;
  }
}

@media (max-width:1024px) and (max-height: 1366px) {
  #categorias-overlay .box__img--original {
    max-height: calc(100vh - 50%) !important;
    margin-top: calc((100vh - 50%) / -2) !important;
  }

  #categorias-overlay .box__text {
    bottom: 26%
  }

}


@media (max-height: 860px) {
  #slider.banner-ppal .slider-caption h2 .color-blanco {
    font-size: 44px;
    letter-spacing: 2px
  }

  #slider.banner-ppal .slider-caption h2 .color-dorado {
    font-size: 32px;
    letter-spacing: 2px
  }

  #slider.banner-ppal .slider-caption p {
    font-size: 15px;
    margin-top: 16px
  }

  .box__title-inner {
    font-size: 24px
  }

  #header.header-home #logo img,
  #header.header-home #header-wrap #logo img {
    top: 20px
  }

  #categorias-overlay .box__title-inner,
  #categorias-overlay .box__title-inner:hover {
    font-size: 40px;
    letter-spacing: 1px;
    margin-bottom: 10px
  }

  #categorias-overlay .box__text .box__text-inner span {
    font-size: 64px
  }

  //    #categorias-overlay .modulo { position: relative; top: 100px}
  #slider.banner-ppal .slider-caption h2 {
    line-height: 54px
  }

  .woocommerce-account:not(.logged-in) .contenedor-mitad {
    padding-top: 80px
  }

  #categorias-overlay .box__img--original {
    max-height: calc(100vh - 100px) !important;
    margin-top: calc((100vh - 100px) / -2) !important;
  }
}


@media (max-height: 640px) {

  #categorias-overlay .box__title-inner,
  #categorias-overlay .box__title-inner:hover {
    font-size: 30px;
    margin-bottom: 4px
  }

  .modulo .texto-categoria {
    font-size: 13px
  }

  #categorias-overlay .vermas {
    margin-top: 20px !important;
    font-size: 12px !important
  }

  #slider.banner-ppal .slider-caption h2 .color-blanco {
    font-size: 40px
  }

  #slider.banner-ppal .slider-caption h2 .color-dorado {
    font-size: 26px;
  }

  #slider.banner-ppal .slider-caption .vermas {
    font-size: 14px !important
  }

  .box__title-inner {
    font-size: 22px;
  }

  #slider.banner-ppal .slider-caption .grid {
    grid-row-gap: 40px;
  }

  #slider.banner-ppal .slider-caption h2 {
    line-height: 48px
  }

  .woocommerce-account:not(.logged-in) .contenedor-mitad {
    min-height: 760px
  }

}


@media (max-height: 500px) {

  #categorias-overlay,
  #slider.banner-ppal .slider-caption h2,
  #slider.banner-ppal .slider-caption .grid {
    display: none
  }

  #slider.banner-ppal .slider-caption-lista {
    margin-top: 100px
  }

}

.woocommerce-password-strength {
  display: none !important
}



.google_form #side_bar label input {
    @media (max-width: 575px) { 
      width: 20px !important;
    }
  
}