#content.content-especial {
  margin-top: 0;
}

#content-mitad {
  min-height: calc(100vh - 363px);

  .titulo-seccion {
    font-size: 34px;
    line-height: 40px;
    font-weight: bold;
    margin-bottom: 40px;
    color: #28474f;

  }

  .pregunta {
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0px;
    font-family: $poppins;
    margin-bottom: 10px;
    color: #28474f;
  }

  p {
    font-size: 13px;
    font-weight: normal;
  }
}

#terminos #content-info {
  min-height: calc(100vh - 363px);
}





.pregunta {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0px;
  font-family: $poppins;
  margin-bottom: 10px;
  color: #28474f;
}