#puntos-retiro {
  .titulo-seccion {
    font-size: 34px;
    line-height: 40px !important;
    font-weight: bold;
    margin-bottom: 40px;
    color: #28474f;
    max-width: 400px;
  }

  #asl-storelocator.storelocator-main.asl-bg-0 p a#condiciones {
    color: $dorado;
    text-decoration: underline !important;
    margin-bottom: 20px;
    display: inline-block;
  }

  #asl-storelocator.container.no-pad.storelocator-main.asl-p-cont.asl-bg-0 {
    max-width: none !important;
    width: 100% !important;
  }

  #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-map {
    margin-left: 0;
    position: relative;

    .store-locator,
    #asl-map-canv {
      height: 100% !important;
    }
  }

  #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-panel {
    padding: 100px 70px !important;
    position: relative;
  }

  #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-panel #panel.storelocator-panel {
    position: relative;
    padding-left: 0 !important;
    margin-bottom: 80px;
    margin-top: 0px;
    width: 50% !important;
  }

  #asl-storelocator.asl-p-cont.asl-bg-0 #panel.storelocator-panel,
  #asl-storelocator.asl-p-cont.asl-bg-0 .inside.search_filter,
  #asl-storelocator.asl-p-cont.asl-bg-0 .loading {
    border: 0 !important;
    background-color: transparent;
  }


  #asl-storelocator.asl-p-cont.asl-bg-0 .col-xs-12.search_filter .asl-store-search {
    max-width: 350px;

    input#auto-complete-search {
      border: 0 !important;
      border-radius: 0;
      font-weight: lighter;
      font-family: $poppins;
      font-size: 13px;
      letter-spacing: 1.5px;
    }

    &>span {
      background-color: #000;
    }
  }

  #asl-storelocator.asl-p-cont.asl-text-1 #panel .panel-cont {
    max-width: 300px;
  }

  #asl-storelocator.asl-p-cont .col-xs-12.inside.search_filter {
    padding-left: 0;
  }

  #asl-storelocator.asl-p-cont.asl-bg-0 .item:nth-child(2n) {
    background-color: transparent !important;
  }

  #asl-storelocator.asl-p-cont.asl-template-0.asl-p-cont .asl-panel {
    .texto-categoria {
      line-height: 1.8;
      margin-bottom: 20px;
      font-size: 14px;
    }

    .p-title,
    a.s-distance {
      display: inline;
      color: #666;
      letter-spacing: 0.6px;
      font-size: 12px !important
    }

    a.s-distance {
      font-weight: 700;
    }

    .p-area {
      color: #999;
      padding-left: 0 !important;
      padding-top: 7px !important;
      font-size: 12px;
      font-weight: lighter;
      letter-spacing: 0.6px;
    }
  }

  #asl-storelocator.asl-p-cont.asl-bg-0 #style_0.infoWindow {
    width: 200px;

    h3 {
      background-color: $turquesa_base;
      font-weight: bold;
      font-size: 12px;
    }

    .info-addr {
      width: 95%;
    }

    .info-addr>div {
      margin-bottom: 7px;
      font-size: 11px;
    }

    .asl-buttons,
    .img_box,
    .img_box img {
      display: none;
    }


  }

  .infoBox {
    width: 200px !important;
  }

  .vermas {
    position: absolute;
    bottom: 100px;
    left: 85px;
  }

}


.titulo-seccion {
  font-family: $poppins;
  letter-spacing: 1px;
  color: $turquesa_base;
}


.mfp-container.mfp-s-ready.mfp-inline-holder {
  background-color: rgba(0, 0, 0, 0.8);
}

.page-template-puntos_retiro .mfp-close {
  font-size: 44px;
  font-weight: lighter;
}

#modal-contacto .divcenter {
  max-width: 1200px !important;
  overflow: auto;
  background-color: $turquesa_base !important;

  div.validation_error {
    border: 0 !important;
    color: $beige !important;
  }

  .subtitulo-ppal {
    color: $beige;
  }

  p {
    color: $beige;
    margin: 20px 0;
    line-height: 1.8;
    font-size: 13.5px;
  }

  .gform_body ul li {
    padding: 0 !important;
    max-width: 350px !important;
    margin: 20px 0 !important;

    label {
      display: none;
    }

    input,
    textarea {
      width: 100% !important;
      resize: none;
      font-size: 12px;
      letter-spacing: 1px;
    }

    &.gfield.gfield_error {
      background: transparent !important;
      border: 0 !important;
    }

    &.gfield_error input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
      border: 0 !important;
    }
  }

  .validation_message,
  .gform_confirmation_message_1 {
    color: $beige !important;
  }

  .gform_footer {
    margin-top: 0;
    padding-top: 0;
  }

  .gform_button {
    display: block;
    width: 100px;
    margin: 0 3px 0 0;
    background-color: transparent;
    border: 2px solid $beige;
    line-height: 20px;
    height: 35px;
    font-size: 12px;
    text-transform: none;
    float: right;
  }

  .tienda_contenedor {
    padding: 0 60px;

    .titulo-seccion {
      font-size: 17px !important;
      letter-spacing: 0px !important;
      line-height: 26px !important;
      margin-bottom: 20px !important;
      color: #FFF !important;
    }

    h5.titulo-seccion {
      font-size: 14px !important;
      line-height: 20px !important;
      margin-bottom: 20px !important;
      color: #FFF !important;
    }

    p,
    li {
      line-height: 1.8 !important;
      font-weight: lighter;
      color: #FFF !important;
      font-size: 13.5px;

      strong {
        font-weight: bold !important;
      }
    }

    ul {
      padding-left: 15px;

      li {
        margin-bottom: 10px;
      }
    }
  }

  .contenedor-formulario {
    padding: 24px 60px 0;
  }
}