.onsale.wc-memberships-member-discount {
  display: none;
}

.woocommerce-column.col-1,
.woocommerce-column.col-2 {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 48% !important;
  max-width: 48% !important;
  flex: 48% !important;
  -ms-flex: 48% !important;
  -webkit-box-flex: 0;

  address {
    border-radius: 0px !important;
    background-color: #fff !important;
    color: #28474f !important;
  }
}

td.membership-discount-amount {
  font-size: 18px !important;
  font-weight: 600;
  font-family: "Poppins" !important;
  color: #28474f !important;
}

//.woocommerce-MyAccount-content h2 {font-size:40px!important;}
.woocommerce-EditAccountForm.edit-account {
  .form-row-wide {
    span em {
      display: none;
    }
  }
}

.woocommerce-MyAccount-content {
  button.vermas {
    height: unset !important;
    margin-left: 3px !important;
  }
}

.u-columns.woocommerce-Addresses.col2-set.addresses,
.woocommerce-columns.woocommerce-columns--2 woocommerce-columns--addresses.col2-set.addresses {
  padding: 20px;
  background-color: #fff !important;
  border: 1px solid rgba(0, 0, 0, .1);

  h2 {
    font-size: 32px !important;
  }

  .u-column1.col-6.woocommerce-Address::after {
    content: "";
    position: absolute;
    right: 30px;
    background-color: #666 !important;
    height: 100%;
    content: "";
    top: 0;
    width: 1px;
    opacity: 0.1;
  }
}

.order-again .button {
  margin: 0px 5px !important;
  text-decoration: none !important;
  line-height: 35px !important;
  font-size: 12px !important;
  color: $turquesa_base !important;
  text-align: center;
  font-weight: 600 !important;
  letter-spacing: 0.6px;
  background-color: transparent !important;
  padding: 0px 20px !important;
  border: 2px solid $turquesa_base !important;
  float: none !important;
  border-radius: 0 !important;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3) !important;

  &:hover {
    background-color: transparent;
  }
}

.wcsatt-options {
  display: none;
}

.woocommerce table.my_account_orders td,
.woocommerce table.my_account_orders th,
.membership-discount-title a {
  font-size: 13px;
  color: #28474f !important;
  letter-spacing: 0.6px;
}

.membership-discount-title a {
  font-weight: 600 !important;
}

.woocommerce-MyAccount-content .woocommerce-table,
.woocommerce-MyAccount-content .woocommerce-orders-table,
.woocommerce-MyAccount-content .shop_table {
  background-color: #fff;
  padding: 20px !important;
  border-radius: 0px !important;

  th {
    color: $turquesa_base !important;
    letter-spacing: 0.6px !important;
  }

  td span {
    color: $turquesa_base !important;
    letter-spacing: 0.6px !important;
  }

  td {
    color: $turquesa_base !important;
    letter-spacing: 0px !important;
    padding: 6px 12px;
  }

  .product-name a {
    color: $turquesa_base !important;
  }

  .woocommerce-button,
  a.button {
    margin: 0px 5px !important;
    line-height: 25px !important;
    font-size: 11px !important;
    color: $turquesa_base !important;
    text-align: center;
    font-weight: 600 !important;
    letter-spacing: 0.6px;
    background-color: transparent !important;
    padding: 0px 20px !important;
    border: 2px solid $turquesa_base !important;
    float: none !important;
    border-radius: 0 !important;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3) !important;
    height: 28px;

    &:hover {
      background-color: transparent;
    }
  }

  .woocommerce table.my_account_orders td,
  .woocommerce table.my_account_orders th {
    padding: 10px 8px !important;
  }

  .woocommerce-orders-table__cell-order-number a,
  .order .order-number a {
    color: #b0995e !important;
    text-decoration: underline !important;
  }
}

.contenedor-tienda {
  background-color: $beige !important;

  .products.prdctfltr-added-wrap {
    .woocommerce-info {
      background-color: transparent;
      border: 0;
      font-weight: 500;
      color: $turquesa_base;
      padding: 0;
      font-size: 16px;

      &::before {
        display: none;
      }
    }
  }
}

.woocommerce-breadcrumb {
  font-weight: 600;
  letter-spacing: 0px;
  margin-bottom: 0 !important;

  a {
    font-weight: lighter !important;
    color: #777;
  }

}

#banner-shop {
  height: auto;
  margin: 30px 0;
}

.single-product .texto-inside {
  line-height: 300px;
  max-width: 850px;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: absolute;

  span {
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 2px;
  }
}

.woocommerce-result-count {
  display: none;
}

.portfolio.portfolio-5 {
  margin: 0 -30px -30px 0;

  .portfolio-item {
    padding: 0 30px 30px 0;

    .back-blanco {
      padding-bottom: 10px;
      position: relative;
      text-align: center;

      img {
        max-width: 200px;
        height: auto;
      }
    }

    .woocommerce-loop-product__title {
      font-family: $poppins;
      font-size: 14px;
      text-align: center;
      margin-bottom: 0;
      color: $turquesa_base;
    }

    .price {
      display: block;
      text-align: center;

      .amount,
      .amount:hover {
        color: #b3b3b5;
        font-weight: 500;
        font-size: 14px;
      }

      del {
        color: transparent;
      }

      ins {
        color: transparent;

        .amount {
          color: $dorado;
        }
      }
    }

    .add_to_cart_button {
      display: none;
      position: absolute;
      width: 100%;
      margin: 0;
      bottom: 0px;
      background-color: #FFF !important;
      height: 48px;
      line-height: 48px;

      &:hover {
        background-color: #FFF !important;
      }
    }

    .product_type_simple:not(.add_to_cart_button) {
      display: none;
    }

    &:hover .add_to_cart_button {
      display: block;
    }

    &.outofstock {
      pointer-events: none;

      img {
        opacity: 0.5;
      }

      .button {
        display: none;
      }

      .back-blanco:before {
        content: "Sin Stock";
        background-color: $dorado;
        padding: 5px 10px;
        color: #FFF;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        display: block;
        position: absolute;
        right: 0;
        z-index: 2;
      }
    }

    .pw_brand_product_list {
      display: none;
    }
  }

}

.product.sale .onsale {
  background-color: $dorado;
  padding: 5px 23px;
  color: #FFF;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  display: block;
  position: absolute;
  right: 0;
  left: auto;
  top: 0;
  border-radius: 0;
  min-height: 1px !important;
  min-width: 1px !important;
  z-index: 2;
  line-height: 18px !important;
  display: none;
}

.product.outofstock {
  pointer-events: none;

  img {
    opacity: 0.5;
  }

  .button {
    display: none;
  }

  .back-blanco:before {
    content: "Sin Stock";
    background-color: $dorado;
    padding: 5px 10px;
    color: #FFF;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    display: block;
    position: absolute;
    right: 0;
    z-index: 2;
  }
}

.woocommerce div.product span.price {
  color: $turquesa_base !important;
  font-size: 12px !important;
}

.shopping {
  margin: 0 -30px -30px 0;

  .product {
    padding: 0 10px 20px 10px;

    .back-blanco {
      padding: 20px 0 10px;
      position: relative;
      text-align: center;

      img {
        max-width: 200px;
        height: auto;
        margin-bottom: 10px;
      }

      .woocommerce-LoopProduct-link>div:not([class]) {
        display: block !important;
      }
    }

    .woocommerce-loop-product__title {
      font-family: $poppins;
      font-size: 13.4px;
      text-align: center;
      margin-bottom: 20px;
      color: $turquesa_base;
      padding: 0px 10px;
      line-height: 18px;
    }

    .precionormal {
      font-size: 13px;
      display: block;

      .amount,
      .amount:hover {
        color: $turquesa_base;
        font-weight: 500;
        font-size: 13px;
      }
    }

    .price {
      display: block;
      text-align: center;

      .amount,
      .amount:hover {
        color: $turquesa_base;
        font-weight: 500;
        font-size: 13px;
      }

      del {
        color: transparent;
      }

      ins {
        color: transparent;

        .amount {
          color: $dorado;
        }
      }

      .wcsatt-sub-discount {
        display: none;
      }

      .subscription-details {
        color: $turquesa_base;
        font-size: 11px;
      }
    }

    .add_to_cart_button {
      padding: 0px 14px !important;
      letter-spacing: 0px;
      height: 34px;
      line-height: 30px;
      margin: 6px 0px 14px 0px !important;
      font-size: 11px !important;

    }

    .pw_brand_product_list {
      display: none;
    }
  }

}

.back-blanco {
  .conprecio-joker {
    color: #b3b3b5;
    font-size: 12px;
    margin-top: 0px;
    padding: 0px 7px;
    text-align: center;
  }
}

.woocommerce a.added_to_cart {
  display: none !important;
}

.add_to_cart_button,
.single_add_to_cart_button {
  background-color: transparent !important;
  margin: 0 !important;
  font-size: 12px !important;
  color: $turquesa_base !important;
  text-align: center;
  font-weight: 600 !important;
  letter-spacing: 0.6px;
  padding: 0px 20px !important;
  border: 2px solid $turquesa_base !important;
  float: none !important;
  border-radius: 0 !important;
  text-transform: none;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3) !important;

  &:hover {
    background-color: transparent;
  }
}

.single_add_to_cart_button {
  left: 17px !important;
}

.woocommerce-pagination {
  margin-top: 90px;
}

.prdctfltr_wc {
  margin-bottom: 20px !important;

  .prdctfltr_filter_title {
    i {
      color: $dorado;
    }

    .prdctfltr_woocommerce_filter_title {
      font-size: 20px;
      color: $turquesa_base;
    }

    span:not(.prdctfltr_woocommerce_filter_title) {
      display: none;
    }
  }

  form.prdctfltr_woocommerce_ordering {
    margin: 0;

    .prdctfltr_filter_inner {
      .prdctfltr_regular_title {
        font-size: 14px;
        color: $turquesa_base;
        letter-spacing: 0.6px;
      }

      .prdctfltr_checkboxes {
        label {
          font-family: $poppins;
          font-weight: normal;
          font-size: 12.6px;
          text-transform: none;
          color: #666;
          margin-bottom: 0px;
          letter-spacing: 0.4px;
        }
      }
    }

    .prdctfltr_columns_4 .prdctfltr_filter {
      max-width: 250px;
    }

    .prdctfltr_columns_4 .prdctfltr_filter:first-child {
      padding-left: 0;
    }

    .prdctfltr_columns_4 .prdctfltr_filter:last-child {
      padding-right: 0;
    }

    .prdctfltr_reset label {
      text-transform: none;
      color: $turquesa_base;
      font-weight: 500;
    }
  }
}

.prdctfltr-pagination-load-more span {
  font-size: 12px !important;
  color: $turquesa_base !important;
  font-weight: 600 !important;
  letter-spacing: 0.6px;
  padding: 10px 20px !important;
  border: 2px solid $turquesa_base !important;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3) !important;
  line-height: 18px !important;
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
}

.search {
  #tienda {
    display: none;
  }

  .woocommerce-products-header {
    padding-top: 80px;
  }
}

.page-template-buscador #portfolio {
  width: 100%;
}

.prdctfltr-widget .prdctfltr_wc .prdctfltr_woocommerce_ordering .prdctfltr_filter {
  max-width: 100%;
}

.toggle {
  display: none;
}

.toggle .togglet {
  padding-left: 33px;
  color: #000;
  letter-spacing: 0.6px;
  font-size: 15px;
}

.toggle .togglet i {
  font-size: 22px;
  color: #b0995e;
  line-height: 21px;

  &.icon-angle-down,
  &.icon-angle-up {
    left: 90px;
    top: 1px;
    font-size: 20px;
  }
}

.toggle .togglec {
  padding-left: 0;
  padding-top: 20px;
}

.prdctfltr_wc form .widgettitle {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  margin-bottom: 0px;
  text-transform: none;
}

.prdctfltr_wc form .widgettitle span {
  color: #28474f;
  font-size: 13px;
}

.wcsatt-sub-options,
.search-results .toggle {
  display: none;
}





.prdctfltr_filter {
  background: #fff;
  padding: 20px 20px 10px 20px !important
}

.prdctfltr_wc_widget .prdctfltr_filter .widgettitle span {
  font-weight: bold;
  font-size: 13.6px !important;
  margin-bottom: 4px;
  color: #000;
  display: inline-block;
}

.tax-product_cat,
.post-type-archive {
  .etiqueta-filtros {
    display: inline-block;
    float: left;
    font-size: 18px;
    position: relative;
    top: -5px;
    letter-spacing: 1px;
    color: #000;
    font-weight: bold;

    i {
      color: $dorado;
      margin-right: 6px;
      position: relative;
      top: 1px;
    }
  }

  .woocommerce-breadcrumb {
    float: left;
    display: inline-block;
    width: calc(100% - 300px);
    padding-bottom: 10px !important;
    margin-bottom: 20px !important;
    padding-left: 170px !important;
    font-size: 12.6px !important;
    position: relative;
    z-index: 100;
  }

  .resultadoscateogria {
    right: 20px;
    top: 0px;
    position: absolute;
    font-size: 12.6px;
    color: #777;
  }

  .prdctfltr_filter {
    background-color: transparent;
  }
}

body .prdctfltr-widget .prdctfltr_wc .prdctfltr_woocommerce_ordering .prdctfltr_filter {
  margin-bottom: 10px !important;
  padding: 0 !important;
}

.prdctfltr_reset,
.prdctfltr_count {
  display: none !important
}

.woocommerce .cart .button,
.woocommerce .cart input.button {
  width: auto !important
}

.postcontent .product:nth-of-type(4n+1) {
  clear: both;
}

.postcontent.shopping {
  position: relative;
  left: 10px
}

.tax-product_cat #content .content-wrap,
.post-type-archive #content .content-wrap {
  padding-top: 0px !important
}

.woocommerce-billing-fields .google_form {
  display: none;
}

.blockUI.blockOverlay {
  z-index: 100 !important;
}

td.actions {
  display: none;
}

.mensual_wrapper {
  margin-bottom: 20px;
  display: block;
  position: relative;
}

#single-producto .summary .price .precionormalmember {
  display: block;
}

.serjokerinterno strong {
  font-weight: normal
}

.shopping .product .price {
  font-size: 12.6px
}

.wp-pagenavi {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  top: 30px
}

.wp-pagenavi a,
.wp-pagenavi span {
  border: 2px solid #28474f !important;
  color: #28474f;
  padding: 5px 10px !important
}


#relacionados-carrusel a.button,
#interes-carrusel a.button {
  height: 32px;
  line-height: 30px !important;
  width: 160px;
  margin: 10px auto 20px auto !important;
  font-size: 11px !important;
  letter-spacing: 0px;
  display: block !important
}

#interes-carrusel .price,
#relacionados-carrusel .price {
  font-size: 12.6px;
  color: #28474f !important
}

#interes-carrusel span,
#relacionados-carrusel span {
  font-size: 13px !important;
  color: #28474f !important
}

#relacionados-carrusel .precionormalmember,
#relacionados-carrusel .serjokerinterno {
  display: block;
  text-align: center;
}

#interes-carrusel a.woocommerce-LoopProduct-link {
  pointer-events: none;
  cursor: default;
}


.woocommerce-breadcrumb {
  display: none !important
}

b,
strong {
  font-weight: 600;
}

.post-ratings {
  color: #666;
}

button,
input {
  border-radius: 0px !important
}

body .woocommerce form .form-row-first,
body .woocommerce form .form-row-last,
body .woocommerce-page form .form-row-first,
body .woocommerce-page form .form-row-last {
  width: 49%
}

.tax-product_cat #banner-shop {
  span {
    width: 100%;
    display: block;
  }
}

.tax-product_cat {
  #banner-shop {
    padding: 35px 0px;

    .texto-inside {
      span p {
        color: #fff;
        line-height: 20px;
        font-weight: 500;
        max-width: 740px;
        margin: auto;
        letter-spacing: 0.8px;
        margin-top: 15px;
      }

      span.color-dorado {
        line-height: 20px;
      }
    }
  }
}

body.archive.post-type-archive {
  #banner-shop {
    padding: 45px 0 !important;
  }
}

.tax-product_cat .texto-inside,
.post-type-archive .texto-inside {
  text-align: center;

  //    line-height: 140px;
  span {
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 2px;
  }

  span.color-dorado {
    line-height: 20px !important;

    p {
      font-weight: 100;
      color: #b0995e !important;
      text-decoration: underline !important;
      font-size: 14px;
      letter-spacing: 0.8px;
    }
  }
}

#filter-form{
  button{
    display:none;
  }

  .category-filters{
    label{
      font-family: "Poppins", sans-serif;
      font-weight: normal;
      font-size: 12.6px;
      text-transform: none;
      color: #666;
      margin-bottom: 6px;
      letter-spacing: 0.4px;
    }
    input[type="checkbox"]{
      content: '';
      display: inline-block;
      width: 9px;
      height: 9px;
      padding: 0;
      border: 1px double #ccc;
      margin-top: 3px;
      margin-bottom: 5px;
      margin-right: 2px;
      vertical-align: middle;
      box-sizing: content-box;
      background-color: #fff;
    }
  }

  .nav-else{
    

    p{
     
        font-family: "Poppins", sans-serif;
        font-weight: normal;
        font-size: 12.6px;
        text-transform: none;
        color: #666;
        margin-bottom: 6px;
        letter-spacing: 0.4px;
      
    }
    p, p a{
      
      a{
        font-family: "Poppins", sans-serif;
        font-weight: normal;
        font-size: 12.6px;
        text-transform: none;
        color: #666;
        margin-bottom: 6px;
        letter-spacing: 0.4px;
      }
    }
    &::before{
      content:'Podria interesarte';
      color: #000;
      font-weight: bold;
        font-size: 12.6px;
        text-transform: none;
        display: block;
        margin-top:25px;
        margin-bottom: 10px;
        letter-spacing: 0.4px;
    }
  }


}


