$poppins: 'Poppins',
sans-serif;
$naranja: #ff7046;
$beige: #f2f2f2;
$turquesa: #1b3035;
$turquesa_base: #28474f;
$dorado: #b0995e;

.color-naranja {
  color: $naranja !important;
}

.color-dorado {
  color: $dorado !important;
}

.color-blanco,
.color-blanco p {
  color: #FFF !important;
  padding-right: 10px
}

.home #content a:not(.vermas),
.home #content a:not(.vermas):hover {
  color: $dorado !important;
  text-decoration: underline !important;
}

.background-full {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.back-beige {
  background-color: $beige;
}

.back-blanco {
  background-color: #FFF;
}

.back-negro {
  background-color: #1a1a1a;
}

.back-gris-oscuro {
  background-color: #1c1c1c;
}

.back-turquesa {
  background-color: $turquesa;
}

.back-turquesa-base {
  background-color: $turquesa_base;
}

.centrado-horizontal {
  margin: 0 auto;
  float: none;
}

body {
  font-family: $poppins;
  overflow-x: hidden;
  background-color: $beige;
}

body p {
  font-size: 13px;
  color: #666;
}

#content #content-mitad.content-wrap {
  padding: 90px 0px;
}

#header {
  #top-search form {
    label {
      width: 100%;
      height: 100%;
    }

    input {
      padding: 10px 130px 10px 10px;
      font-family: $poppins;
      color: #fff;
      box-sizing: border-box;
      width: 100%;
      border: 0 !important;
      font-size: 22px;
      letter-spacing: 1px;

      &::placeholder {
        text-transform: none;
        color: #fff;
        opacity: 0.7;
        font-size: 22px;
        letter-spacing: 1px;
      }
    }
  }

  #top-cart i {
    color: #FFF;
    font-size: 16.6px;
  }

  #top-search i {
    color: #FFF;
    font-size: 15.2px;
  }

  #top-session i {
    color: #FFF;
    font-size: 15px;
  }

  #top-search-trigger .icon-line-cross {
    font-size: 18px;
    font-weight: bold
  }

  #primary-menu>ul {
    >li {
      &.mega-menu {
        position: relative;
      }

      >a {
        font-family: $poppins;
        font-weight: bold;
        color: #FFF;
        letter-spacing: 1.6px;

        &:hover {
          color: $dorado;
        }
      }

      &#menu-tabacos .mega-menu-content,
      &#menu-puros .mega-menu-content {
        left: -35px;
      }

      &#menu-cannabis .mega-menu-content,
      &#menu-shishas .mega-menu-content {
        left: -35px;
      }
    }

    .mega-menu-content {
      width: max-content !important;
      border-top-color: $dorado;
      top: 70px;

      ul.mega-menu-column {
        border-left: 0;
        padding: 6px 12px;

        li {
          color: #666;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 12.5px;
          letter-spacing: 0.6px;

          &:hover a {
            background-color: transparent;
            color: $dorado;
          }

          a {
            color: #999;
            padding: 0px !important;
            margin: 10px 0px !important;
            letter-spacing: 0.6px;
            font-size: 12px;
          }
        }
      }
    }
  }
}

#header.header-home,
#header.header-home #header-wrap {
  border-bottom: 0 !important;

  #logo {
    border: 0;

    img {
      position: absolute;
      top: 30px;
      left: 10px;
      max-width: 140px;
      height: auto !important;
    }

    &.logo-sticky {
      display: none;
    }
  }

  #top-session {
    float: right;
    margin: 38px 0 38px 14px;
    position: relative;
    cursor: pointer;

    a {
      display: block;
      position: relative;
      width: 19px;
      height: 19px;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #333;
      -webkit-transition: color 0.3s ease-in-out;
      transition: color 0.3s ease-in-out;

      img {
        position: relative;
        top: -1px;
      }
    }
  }

  #top-cart {
    padding-right: 12px;
  }

  #top-search {
    padding-right: 6px;
  }

  #primary-menu ul {
    display: none;
  }

}

#primary-menu-trigger {
  color: #FFF;
  position: absolute;
  left: 25px !important;
  right: auto !important;

  i,
  span {
    display: inline;
    font-size: 15px;

    &.textomenu {
      padding-left: 10px;
      letter-spacing: 3px;
      font-weight: bold;
    }
  }

}

#header.header-home.sticky-header #header-wrap {
  background-color: $turquesa;

  #logo {
    &.logo-sticky {
      display: block;
    }

    &.logo-banner {
      display: none;
    }

    img {
      position: relative;
      top: 16px;
      left: auto;
      max-width: 100px;
    }
  }

  #primary-menu>ul {
    display: block;
    border-right: 0;
  }
}

#header:not(.header-home) #header-wrap {
  background-color: $turquesa;

  #logo {
    img {
      position: relative;
      top: 16px;
      left: auto;
      max-width: 100px;
      height: auto !important;

    }
  }

  #top-session {
    float: right;
    margin: 38px 0 38px 12px;
    position: relative;
    cursor: pointer;

    a {
      display: block;
      position: relative;
      width: 19px;
      height: 19px;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #333;
      -webkit-transition: color 0.3s ease-in-out;
      transition: color 0.3s ease-in-out;

      img {
        position: relative;
        top: -1px;
      }
    }
  }

  #top-cart {
    padding-right: 14px;
  }

  #top-search {
    padding-right: 6px;
  }


}

body.modern #header:not(.header-home) #header-wrap {
  #top-cart {
    padding-right: 15px;
  }
}

.overlay-menu #primary-menu>ul:first-child {
  padding-top: 100px
}

.overlay-menu #primary-menu>ul {
  min-height: 100vh;
  background-color: $beige;

  li {
    text-align: left;
    max-width: 900px;

    a {
      font-family: $poppins;
      font-size: 32px;
      font-weight: bold;
      padding: 26px 15px;
      color: $turquesa_base;

      &:hover {
        color: $dorado;
      }
    }

    &.logo-overlay {
      position: absolute;
      top: 34px;
      left: 44px;
      max-width: 150px;
    }

  }

}



#overlay-menu-close {
  opacity: 1 !important;
  right: 50px !important;
  width: 70px !important;

  a {
    padding: 0;
  }

  i {
    top: 7px;
    padding-right: 5px;
    color: $dorado;
  }

  span {
    color: $turquesa_base;
  }

  i,
  span {
    display: inline;
    font-size: 15px;
    font-weight: bold !important;

    &.textomenu-open {
      letter-spacing: 3px;
      font-weight: bold !important;
    }
  }
}

body.top-search-open #top-cart {
  opacity: 1 !important;
}

.menulogin.clearfix {
  background-color: #fff;
  position: absolute;
  left: auto;
  right: 6px;
  width: 180px;
  top: 62px;
  display: none;

  &::after {
    content: "";
    background-image: url(imagenes/corte-smoke.png);
    background-size: cover;
    position: absolute;
    top: -32px;
    right: 0px;
    width: 32px;
    height: 32px;
  }

  ul {
    padding: 15px 20px;
    display: none;
  }

  li {
    width: 100%;
    margin-bottom: unset !important;

    a {
      width: 100% !important;
      color: #666 !important;
      font-size: 12px !important;
      letter-spacing: 0.6px !important;
      text-align: left !important;
      padding: unset !important;
      height: auto !important;
      margin-bottom: 6px;
      text-transform: none !important;
      font-weight: normal !important;
      font-family: "Poppins", sans-serif !important;

      &:hover {
        color: #b0995e !important;
      }
    }

    &:last-child a {
      margin-bottom: 0px !important;
    }
  }
}


//body.top-search-open #primary-menu-trigger,
//body.top-search-open .sticky-header #header-wrap #primary-menu-trigger{
//    opacity: 0 !important;
//}
//
//body .sticky-header #header-wrap #primary-menu-trigger {
//    opacity: 1 !important;
//    transform: none !important;
//    z-index: 10 !important;
//}