
/*-----------------------------------------------------------------------------------
	typography.scss
-----------------------------------------------------------------------------------*/


dl, dt, dd, ol, ul, li {
	margin: 0;
	padding: 0;
}

.clear {
	clear: both;
	display: block;
	font-size: 0px;
	height: 0px;
	line-height: 0;
	width: 100%;
	overflow:hidden;
}

::selection {
	background: #1b3035;
	color: #FFF;
	text-shadow: none;
}

::-moz-selection {
	background: #1b3035; /* Firefox */
	color: #FFF;
	text-shadow: none;
}

::-webkit-selection {
	background: #1b3035; /* Safari */
	color: #FFF;
	text-shadow: none;
}

:active,
:focus { outline: none !important; }


/* ----------------------------------------------------------------
	Typography
-----------------------------------------------------------------*/


body {
	line-height: $line-height-base;
	color: $body-color;
	font-size: $font-size-base;
	font-family: $body-font;
}

a {
	text-decoration: none !important;
	color: $theme-color;
	&:hover {
		color: #222;
	}
	img {
		border: none;
	}
}

img { max-width: 100%; }
iframe { border: none !important; }


/* ----------------------------------------------------------------
	Basic Layout Styles
-----------------------------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $heading-color;
	font-weight: 600;
	line-height: $line-height-base;
	margin: 0 0 30px 0;
	font-family: $heading-font;
}

h5,
h6 { margin-bottom: 20px; }

h1 { font-size: $font-size-h1; }
h2 { font-size: $font-size-h2; }
h3 { font-size: $font-size-h3; }
h4 { font-size: $font-size-h4; }
h5 { font-size: $font-size-h5; }
h6 { font-size: $font-size-h6; }

h4 { font-weight: 600; }

h5,
h6 { font-weight: bold; }

h1,
h2,
h3,
h4,
h5,
h6 {
	> span:not(.nocolor) {
		color: $theme-color;
	}
}

p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form { margin-bottom: 30px; }

small { font-family: $body-font; }

#wrapper {
	position: relative;
	float: none;
	width: 1220px;
	margin: 0 auto;
	background-color: #f2f2f2 !important;
	@include box-shadow(0 0 10px rgba(0,0,0,0.1));
}