

.vermas {
    font-size: 12.4px !important;
    color: $turquesa_base !important;
    font-weight: 600 !important;
    letter-spacing: 0.6px;
    padding: 10px 20px !important;
    border: 2px solid $turquesa_base !important;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3) !important;
    line-height: 18px !important;
    display:block;
    width: max-content;
    margin-top: 40px !important;
    height: auto;
}

.subtitulo-ppal {
    font-family: $poppins;
    color: $turquesa_base;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 31px;
}

.modulo {
    padding: 0px !important;
    &.grid__item-content {
        padding: 0px 80px !important;
    }
    .titulo-seccion {
        font-family: $poppins;
        color: $turquesa_base;
        letter-spacing: 1px;
        font-size: 30px;
        max-width: 270px;
        line-height: 42px;
        margin-bottom: 20px;
        font-weight: bold
    }
    a {
        pointer-events: auto;
    }
    .vermas.tienda {
        display: inline;
        max-width: 530px;
        margin-top: 30px;
    }
    .texto-categoria p, .texto-categoria {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 0px;
        position: relative;
    }
    .texto-categoria p { max-width: 380px;}
    .texto-categoria { max-width: 380px; }
    
    p { margin-bottom: 0px}
    &.back-turquesa-base {
        .titulo-seccion,
        .texto-categoria {
            color: #FFF;
        }
        .vermas {
            color: #FFF !important;
            border-color: #FFF !important;;
        }
    }
    a.condiciones {margin-top: 30px;}
}

#slider.banner-ppal {
    .slider-caption-lista {
        position: relative;
        float: right;
        width: 100%;
        margin-top: 190px
    }
    .slider-caption {
        padding-left: 37px;
        max-width:430px;
        padding-top: 20px;
        h2 {
            font-family: $poppins;
            text-transform: none;
            font-weight: 700;
            letter-spacing: 4px;
            line-height: 60px;
            .color-blanco {
                font-size: 50px;
            }
            .color-dorado {
                font-size: 36px;
                display: block;
            }
        }
        p {
            font-size: 15.6px;
            letter-spacing: 1px;
            margin-top: 24px;
            font-weight: normal;
        }

        .grid {
            grid-template-columns: repeat(1, 100%);
            grid-row-gap: 50px;
            .box {
                margin: 0 !important;
            }
            .grid__item {
                margin: 0 !important;
                display: block;
                height: auto;
            }
            .box__title {
                left: 0;
                right: auto;
                top: 0;
            }
        }
        .vermas {
            border-color: #fff !important;
            color: #fff !important;
            margin-top: 20px !important;
            display: inline-block;
            font-size: 14.6px !important;
            line-height: 20px !important;
            letter-spacing:0.4px;
        }
    }
}

.box__title-inner {
    font-size: 25px;
    letter-spacing: 2px;
    font-weight: 700;
    font-family: $poppins;
    color: #b0995e !important;
    -webkit-text-stroke: 0;
    -webkit-text-fill-color: #FFF;
    text-transform: none;
    &:hover {
        -webkit-text-fill-color: $dorado;
    }
}

#categorias-overlay {
    .overlay__item {
        justify-content: center;
        padding: 44px 40px;
        background-color: $beige;
        min-height: 100vh;
        position: fixed;
    }
    .overlay__content {
        margin-left: 0;
    }
    .box__title {
        left: 0px;
        right: auto;
        top: 0;
        position: relative;
        text-align: left;
    }
    .modulo {
        position: relative;
        padding: 0 !important;
        max-width: none;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100vh;
        margin-top: -14px;
    }
    .modulo .texto-categoria {
        text-align: left;
    }
    .box__title-inner,
    .box__title-inner:hover {
        -webkit-text-fill-color: $turquesa_base !important;
        font-size: 44px;
        line-height: 48px;
        letter-spacing: 1px;
        margin-bottom: 26px;
    }
    .vermas {
        font-size: 12px;
        padding: 10px 36px;
        letter-spacing: 1px;
        max-width: 200px;
        margin-top: 30px !important;
    }
    .box__img--original {
        position: absolute;
        right: 140px;
        top: 50%;
        width: auto;
        max-height: calc(100vh - 100px) !important;
        margin-top: calc((100vh - 100px) / -2) !important;
        
    }
    .box__text {
        top: auto;
        bottom: 50px;
        right: 10px;
        .box__text-inner {
            border: 0;
            color: $turquesa_base;
            font-family: $poppins;
            font-weight: bolder;
            font-size: 20px;
            line-height: 0;
            span {
                font-size: 80px;
                position: relative;
                top: -20px;
                margin-right: 0px;
            }
        }
    }
    .overlay__close {
        font-size: 30px;
        position: fixed;
        color: $turquesa_base;
        top:20px;
        right: 20px;
    }

    .owl-carousel .owl-nav [class*="owl-"],
    .owl-carousel .owl-nav [class*="owl-"]:hover {
        background-color: transparent !important;
        border: 0;
        color: $turquesa_base !important;
        opacity: 1 !important;
        font-size: 46px;
        margin: 0px;
    }
    .owl-carousel .owl-nav .owl-next,
    .owl-carousel .owl-nav .owl-next:hover {
        right: -20px;
        margin-top: -25px;
    }
    .owl-carousel .owl-nav .owl-prev,
    .owl-carousel .owl-nav .owl-prev:hover {
        right: -20px;
        left: auto;
        margin-top: 25px;
    }
    .vermas {
        display: none
    }

    #categorias-carrusel .oc-item .logo-overlaycat {
        position: absolute;
        top: 23px;
        left: 0px;
        max-width: 140px;
    }
}

.preview-open .vermas {
    display: block !important
}

#footer,
#footer2 {
    margin-top: 0 !important;
    padding: 30px 0 20px 0px;
    background-color: $turquesa;
    border-top: 0;
    .subscribe-widget { margin-top: 10px}
    .columna2 ul {
        max-width: 150px;
    }
    .columna3 ul {
        max-width: 230px;
    }
    ul {
        margin-bottom: 0;
        li {
            list-style: none;
            margin: 5px 0;
            a {
                color: #fff;
                font-size: 11.5px;
                font-weight: normal;
            }
        }
    }
    .columna4 ul li {
        display: flex;
        justify-content: flex-end;
        a {
            font-size: 14px;
            width: 34px;
            height: 34px;
            line-height: 31px !important;
            margin: 0 0 3px;
            border: 2px solid #fff !important;
        }
    }
    .columna4 ul li a:hover {
        background-color: transparent !important;
        border: 2px solid #fff;
        transition: none;
        i {
            transition: none;
            margin-top: 0;
        }
    }
    .contenedormailchimp {
        .titulo {
            color: #fff;
            font-size: 13px;
            font-weight: normal;
            margin-bottom: 8px;
        }
        input[type="email"] {
            width: 280px;
            border: 0;
            padding: 0px 10px 0px;
            font-size: 11.6px;
            height: 30px;
            border-radius: 0;
        }
        input[type="submit"] {
            border: 0;
            padding: 0px 13px 0px;
            height: 30px;
            background-color: $dorado;
            text-transform: uppercase;
            font-family: $poppins;
            color: #FFF;
            font-size: 11px;
            font-weight: bold;
            letter-spacing: 0.3px;
            border-radius: 0;
            
        }
    }
    .contenedor-derechos {
        margin-top: 50px;
        color: #FFF;
        font-size: 11px;
        font-weight: normal;
        a,
        a:hover {
            color: #fff;
        }
        &:before {
            content: "";
            width: 100%;
            display: block;
            position: relative;
            border-top: 1px solid #fff;
            top: -20px;
        }
    }
    .icon-instagram{ font-size: 12px}
    
}

#footer2 {
    width: 100%;
    position: absolute;
    top: 100%;
}

.elementos-centro {
    display: flex;
    justify-content: center;
}

.mfp-fade.mfp-bg.mfp-ready.mfp-bg {
    // background: $beige;
    background: rgba(0,0,0,0.9);
}

#modal-promo {
    .mfp-close {
        color: #FFF;
        font-display: 24px;
        opacity: 1;
    }
    .divcenter {
        padding: 130px 0;
    }
    img {
        max-width: 400px;
    }
    .duracion {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.4px;
    }
    .bajada {
        font-weight: lighter;
        max-width: 300px;
        margin: 0 auto
    }
}

/*GRILLA CON EFECTO*/

#grid-efecto .grid {
    grid-row-gap: 0;
    margin: 0;
    padding: 0;
    .grid__item:nth-child(4n-2),
    .grid__item:nth-child(4n) {
        margin: 0;
    }
}

.error404 {
    font-size: 14px;
    font-weight: lighter;
    color: #FFF;
    line-height: 1.5;
    letter-spacing: 0px;
    .contenedor-tienda {
        min-height: calc(100vh - 363px);
    }
    .titulo-seccion {
        max-width: none;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        height: 70px;
    }
}


.css3-spinner {
    background-color: #1b3035!important;
    .logoloading {
        position: relative;
        top: 50%;
        width: 150px;
        margin-top: -260px
    }
    .css3-spinner-bounce1,
    .css3-spinner-bounce2,
    .css3-spinner-bounce3 {
        background-color: $beige !important;
    }
}

#categorias-overlay p.texto-categoria { z-index: 99999}
.container-fluid { padding-left: 30px; padding-right: 30px}
#header.dark .container-fluid {padding-right: 40px}



/*SER UN JOKER*/

body.page-template-ser-jokers {
    .table { margin: 20px 0px 50px 0px !important} 
    .table td {font-size: 13px !important}
    .table thead th { font-size: 14px; padding: 0px 10px 2px 0px; font-weight: 600; letter-spacing: 0.4px}
    #content-mitad p { font-size: 12px} 
    h5 { max-width: 300px}
}


.contenedor-info {
    padding-left: 60px !important;
    padding-right: 60px !important;
}

body.page-template-ser-jokers {
    #content {
        float: left;
    } 
    #slider {
        left:auto; 
        right: 0;
    }
    h3.titulo-seccion {
        margin-bottom: 10px !important;
    }
    p {
        font-size: 15.4px; 
        margin-bottom: 20px;
    }
    
    .table {
        margin-bottom: 30px;
        max-width: 800px;
        thead th {
            border-bottom: 1px solid #dee2e6;
            color: $turquesa_base;
            border-top: 0;
            &:first-child {padding-left: 0;}
            &:last-child {padding-right: 0;}
        } 
        td {
            color: #666;
            border-top: 0;
            font-weight: 500;
            padding: 12px 10px 0px 0px;
            &:first-child {padding-left: 0;}
            &:last-child {padding-right: 0;}
        }
        tbody tr:last-child td {border-bottom: 1px solid #dee2e6; padding-bottom: 10px}
    }
    .vermas {position: relative;top: 10px;float: right;}
    #top-cart .woocommerce-mini-cart__buttons.buttons .vermas{
        top: 0;
        float: none;
    }
} 

/*VARIOS */
#content-info {padding: 0px 60px}
.page-template-terminos-y-condiciones p { margin-bottom: 10px}
.page-template-terminos-y-condiciones h3.pregunta { margin-top: 30px}
.page-template-terminos-y-condiciones #content { width: 100% }


h5.titulo-seccion {
    font-size: 16px !important;
    letter-spacing: 0px;
    line-height: 22px !important;
    font-weight: normal !important;

}
#grid-efecto .grid__item { cursor: auto }
.iniciar .boot-tabs {margin-bottom: 60px !important;}
.password .boot-tabs {margin-bottom: 40px !important;}
.woocommerce .woocommerce-breadcrumb { font-size: 12px !important}
.ism_wrap { margin-top:20px !important}
.ism_template_4 .ism_item { margin: 4px !important}
.single-product .related { margin-top: 60px}
.woocommerce .quantity .qty { border: none; background: #fff}


.wc-memberships-member-discount-message, .woocommerce-info { display: none !important}

.woocommerce-form-login-toggle .woocommerce-info {display:block!important;}

.woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea { border-radius: none !important}
