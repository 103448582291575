#single-producto {
  .up-sells.upsells products {
    display: none;
  }

  .producto-full {
    width: 100%;
  }

  .contenedor-info {
    padding: 0px 15px 0 40px !important;

    form.cart {
      margin: 40px 0px 0px 0px;

      .wcsatt-options-wrapper {
        display: none;
      }
    }

    p.stock {
      display: none;
    }

    .tabla-detalles p {
      margin-bottom: 5px;
    }
  }

  .product_title.entry-title {
    font-family: $poppins;
    font-size: 30px;
    line-height: 37px;
    color: $turquesa_base;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 10px
  }

  .quantity .qty {
    height: 40px;
    line-height: 40px;
    background-color: #FFF;
    font-size: 15px;
    border: 0;
    font-weight: bold;
    color: #28474f;
    width: 79px;
  }

  .summary .price {
    border-bottom: 2px solid #b3b3b5;
    padding-bottom: 10px;
    margin-bottom: 50px;
    font-size: 15px;

    .amount {
      color: $turquesa_base !important;
      font-size: 18px;
    }

    .precionormal {
      display: inline;
    }

    .precionormalmember {
      display: none;

      .normal {
        margin-right: 5px;
      }
    }

    .serjokerinterno {
      display: inline;
      color: #28474f;
      font-weight: 600;

      strong {
        color: $turquesa_base;
        font-size: 16px;
        margin: 0 10px 0px 5px;
        font-weight: 600;
      }

      a {
        text-decoration: underline !important;
        font-size: 13px;
      }
    }

    del {
      color: transparent;
    }

    ins {
      color: transparent;

      .amount {
        color: $dorado !important;
        padding-left: 10px;
        font-size: 17px;
      }
    }
  }

  .woocommerce-product-details__short-description {
    &>p {
      font-size: 13px;
      margin-bottom: 0px;
    }

    .ism_wrap.ism_template_4 {
      display: block;
      margin-bottom: 20px;
    }
  }

  #relacionados-carrusel {
    padding: 0px;
  }

  #relacionados-carrusel .oc-item {
    img {
      margin: 0 auto;
      max-width: 150px;
      margin-bottom: 20px;
    }

    .price {
      padding-bottom: 0;
      border: 0;
      text-align: left;

      .amount {
        color: $turquesa_base;
        font-weight: 500;
        font-size: 14.6px;
        padding-bottom: 0;
        border: 0;
        text-align: left;
      }
    }

    .portfolio-item {
      width: 100%;
    }

    .price {
      width: 100% !important;
      display: block !important;
      text-align: center !important;
    }

    .woocommerce-loop-product__title {
      font-family: $poppins;
      font-size: 13.6px;
      text-align: center;
      margin-bottom: 0;
      color: $turquesa_base;
      margin-bottom: 20px;
    }

    .portfolio-item .woocommerce-loop-product__title {
      text-align: left;
      padding: 0 20px;
    }

    .back-blanco {
      padding: 20px 0 10px;
    }
  }

  .onsale,
  .wcsatt-add-to-subscription-action-label {
    display: none;
  }

  .portfolio.portfolio-5 {
    max-width: 85%;
    margin: 0 auto;

    .portfolio-item {
      padding: 0 0px 30px 0;
    }
  }

  .reviews {
    display: inline;
    float: right;
    font-size: 12px !important;

    .post-ratings em {
      font-size: 11.4px;
    }
  }

  #indeed_sm_wrap_1942.ism_template_4 {
    margin-top: 10px;
  }

  .up-sells.upsells.products {
    display: none;
  }

  .ism_template_4 .ism_item {
    background-color: linear-gradient(135deg, transparent 0%, transparent 100%);
    box-shadow: none;
    border: 1px solid $turquesa_base;
    line-height: 25px;
    height: 33px;

    i {
      color: $turquesa_base !important;
      font-size: 15px;
      width: 25px;
    }

    &:hover {
      background: transparent !important;
    }
  }
}

.member-discount {
  #single-producto .summary .price .amount {
    font-size: 16px;
    margin-bottom: 4px;
  }

  #single-producto .summary .price .precionormal {
    display: none;
  }

  #single-producto .summary .price .precionormalmember {
    display: block;
  }

  &.member-logged-in #single-producto .summary .price .precionormalmember {
    display: inline;
  }
}


body.woocommerce-page {
  .post-ratings-text {
    display: none !important;
  }

  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning {
    background-color: $dorado
  }

  .bootstrap-switch .bootstrap-switch-handle-off {
    background-color: #ccc;
  }

  .bootstrap-switch .bootstrap-switch-handle-on,
  .bootstrap-switch .bootstrap-switch-handle-off {
    color: #FFF;
    font-weight: bold;
    font-size: 11px;
    letter-spacing: 0.6px;
    font-family: $poppins;
  }

  .bootstrap-switch .bootstrap-switch-label {
    padding: 6px 8px;
  }

  .texto-switch {
    color: #999;
    font-size: 12.6px;

    .tooltip-wrap {
      color: $dorado;

      .tooltip {
        background: $dorado;
        bottom: 100%;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        display: block;
        bottom: 45px;
        margin-bottom: 0px;
        opacity: 0;
        padding: 10px;
        pointer-events: none;
        position: absolute;
        width: 210px;
        -webkit-transform: translateY(10px);
        -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
        -o-transform: translateY(10px);
        transform: translateY(10px);
        -webkit-transition: all .25s ease-out;
        -moz-transition: all .25s ease-out;
        -ms-transition: all .25s ease-out;
        -o-transition: all .25s ease-out;
        transition: all .25s ease-out;
        -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);

        &:before {
          bottom: -20px;
          content: " ";
          display: block;
          height: 20px;
          right: 0;
          position: absolute;
          width: 210px;
        }

        &:after {
          border-left: solid transparent 10px;
          border-right: solid transparent 10px;
          border-top: solid $dorado 10px;
          bottom: -10px;
          content: " ";
          height: 0;
          left: 50%;
          margin-left: -13px;
          position: absolute;
          width: 0;
        }
      }

      &:hover .tooltip {
        opacity: 1;
        pointer-events: auto;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px);
      }

      span {
        color: #ccc !important;
        font-weight: lighter !important;
      }
    }
  }

  .bootstrap-switch-on+.texto-switch .tooltip-wrap span {
    color: $dorado !important;
    font-weight: bold !important;
  }

}

#switch-alerta.style-msg.successmsg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.4s linear;
  display: none;

  &.show-alerta {
    display: block;
    opacity: 1;
    transition: opacity 0.4s linear;
  }
}

.style-msg.successmsg,
.woocommerce-form-login-toggle .woocommerce-info {
  background-color: #eee7d0;
  color: #000;
  border: 0;
  width: 100%;

  i {
    color: $dorado;
    font-size: 30px;
    position: absolute;
    left: 80px;
  }

  .sb-msg {
    text-shadow: none;
    border: 0;
    padding-left: 120px;
  }
}



#full-bannerbajada {
  height: 300px;
  margin-top: 50px;

  .texto-inside {
    max-width: 85%;
  }
}

body.single-product #content .content-wrap {
  padding-bottom: 0 !important;

  .woocommerce-breadcrumb {
    display: block;
  }

  .etiqueta-filtros {
    display: none;
  }
}

body.woocommerce-page .woocommerce-message,
body.woocommerce-page .woocommerce-info,
body.woocommerce-page .woocommerce-error {
  background-color: #eee7d0;
  color: #000;
  border: 0;
  width: 100%;
  position: relative;
  left: 0;
  top: 0px;
  padding: 15px 50px;
  margin-bottom: 30px;
  z-index: 5;

  &:before {
    top: 15px;
  }

  .button {
    position: absolute;
    right: 1.5em;
    top: 10px;
    background-color: transparent;
    font-size: 12px;
    color: $turquesa_base;
    text-align: center;
    font-weight: lighter;
    text-decoration: underline !important;
    text-transform: none;
    padding: 0px 10px;
    border: 0;
    border-radius: 0;
    height: 30px;
    line-height: 27px;

    &:hover {
      background-color: transparent;
    }
  }
}

body.woocommerce-page .owl-carousel .owl-nav [class*="owl-"] {
  opacity: 1;
  background-color: transparent;
  border: 0;
  color: $dorado;
  font-size: 40px;

  &:hover {
    background-color: transparent !important;
    color: $dorado !important;
  }
}


body.woocommerce-page .owl-carousel .owl-nav .owl-next {
  right: 0px;
}

body.woocommerce-page .owl-carousel .owl-nav .owl-prev {
  left: 0px;
}

.woocommerce.wc-memberships.admin-restricted-content-notice {
  display: none
}

.single-product .texto-switch {
  padding-left: 15px
}

.single-product .woocommerce-product-gallery__image a {
  pointer-events: none;
  cursor: default;
}

.wc-memberships-member-discount-message {
  margin-top: 100px !important
}

body.logged-in.member-discount {
  #single-producto .summary .price {

    del,
    del span,
    del .amount {
      color: #b3b3b5 !important;

    }

    ins .amount {
      font-size: 18px;
    }
  }
}

.post-ratings img {
  width: 16px;
  margin-right: 2px !important;
}

.woocommerce-product-details__short-description {
  padding-right: 100px !important
}

#modal-joker {
  .feature-box {
    padding: 60px 80px;

    .titulo-seccion {
      font-weight: bold;
      margin-bottom: 20px;
    }

    h5 {
      font-weight: normal;
      color: $turquesa_base;
      font-family: $poppins;
      margin-bottom: 40px;
      font-size: 16px;
      max-width: none !important;
    }

    p {
      font-size: 13px;
      max-width: 500px;
      margin: 0 auto 20px;

      &.dorado {
        color: $dorado;
      }
    }

    .vermas {
      margin: 20px auto 0px !important;
      background-color: transparent;
      float: none;
    }
  }

  .mfp-close {
    color: $turquesa_base;
    opacity: 1;
  }

  .table {
    thead th {
      border-bottom: 1px solid #dee2e6;
      color: #28474f;
      border-top: 0;
      font-size: 14px;
      padding: 0px 10px 2px 0px;
      font-weight: 600;
      letter-spacing: 0.4px;
    }

    td {
      color: #666;
      border-top: 0;
      font-weight: 500;
      padding: 12px 10px 0px 0px;
      font-size: 13px;
    }

    tr:last-child td {
      border-bottom: 1px solid #dee2e6;
      padding-bottom: 10px;
    }
  }

  form {
    margin-bottom: 0;
  }
}

.precionormalmember {
  color: #28474f
}

#abrir-modal-joker {
  font-weight: normal
}

body.single-product .texto-switch .tooltip-wrap .tooltip {
  left: 240px;
}