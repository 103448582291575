.woocommerce-account.woocommerce-edit-account,
.woocommerce-account.members-area,
.woocommerce-account.orders {
  &.logged-in {
    .woocommerce-MyAccount-content {
      background-color: transparent !important;
      padding: 0px !important;
      border: 0px !important;
    }
  }

}

.woocommerce-MyAccount-navigation-link.woocommerce-MyAccount-navigation-link--edit-address {
  display: none !important;
}

.woocommerce-account {
  .shop_table.shop_table_responsive.my_account_subscriptions.my_account_orders {
    padding: 0px !important;
    background-color: transparent !important;
    padding: 0px !important;
    border: 0px !important;
  }

  .woocommerce a.button {
    border-radius: 0px !important;
    background-color: transparent !important;
    margin-top: 0px !important
  }

  form.edit-account {
    input {
      padding: 10px;
      margin-top: 10px !important;
    }
  }

  legend {
    margin: 30px 0;
    display: block;
  }

  &.logged-in {
    .woocommerce-MyAccount-content {
      .u-columns woocommerce-Addresses.col2-set.addresses {
        border: 0px !important;
      }
    }

    nav ul {
      li {
        list-style: none;


        a {
          color: #666;
          font-weight: 400;
          font-size: 12.6px;
        }

        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
        }
      }

    }
  }

  .woocommerce-pagination {
    margin-top: 30px;
  }

  .encabezado-dashboard {
    .subtitulo-ppal {
      display: block;
      margin-bottom: 20px;

      strong {
        font-weight: bold !important;
      }
    }

    .linea2 {
      font-weight: 500;
      color: $turquesa_base;
      display: block;
    }

    .vermas {
      color: $turquesa_base !important;
      text-decoration: none !important;
      margin: 0px auto 40px !important;
    }
  }

  .bajada-dashboard {
    max-width: 420px;
    margin: 0 auto 30px;
    text-align: center;
  }
}

.woocommerce-edit-address {

  .subtitulo-ppal,
  .edit {
    display: block;
    float: none !important;
  }

  .subtitulo-ppal {
    margin-bottom: 10px;
  }

  .edit {
    margin-bottom: 30px;
    max-width: 88px;
    padding: 5px 20px !important;
  }

  .woocommerce-MyAccount-content input {
    padding: 10px;
    margin: 10px 0 !important;
  }
}

.woocommerce-MyAccount-content {
  width: 78% !important;

  mark,
  .mark {
    background-color: transparent !important;
    color: $turquesa_base !important;
    font-weight: bold;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #28474f !important;
  }

  p {
    font-weight: lighter !important;
    color: #666 !important;

    a {
      color: #b0995e !important;
      text-decoration: underline !important;
    }
  }

  strong {
    font-weight: 500 !important;
  }

  .my_account_memberships {
    th.membership-discount-title a {
      font-weight: 700 !important;
    }

    .membership-discount-excerpt,
    .membership-product-excerpt,
    .membership-discount-actions .add-to-cart {
      display: none;
    }

    td.membership-discount-amount {
      font-size: 13px !important;
    }
  }
}

.woocommerce-account {
  .woocommerce-MyAccount-navigation {
    ul li {
      border-bottom: 1px solid #ddd;
      margin-bottom: 9px;
      padding-bottom: 9px;
    }

    width:245px !important;
    background-color:transparent;

    &.wc-memberships-members-area-navigation {
      ul {
        border-bottom: 1px solid #ddd;

        .woocommerce-MyAccount-navigation-link--my-membership-details,
        .woocommerce-MyAccount-navigation-link--my-membership-notes,
        .woocommerce-MyAccount-navigation-link--my-membership-products,
        .woocommerce-MyAccount-navigation-link--my-membership-content {
          display: none;
        }
      }
    }
  }

  &:not(.logged-in) {
    .woo-slg-social-container {
      margin-top: 40px;

      legend {
        color: #FFF;
        font-weight: normal;
        font-size: 13px;
        letter-spacing: 0.6px;
      }

      .woo-slg-login-wrapper {
        display: block;

        .woo-slg-social-btn {
          max-width: none;
          width: 100%;
        }
      }
    }
  }
}

.woocommerce-account.logged-in nav ul li .woocommerce-MyAccount-navigation {
  li {
    margin-bottom: 15px !important;

    a {
      font-size: 13px;
      color: #28474f;
      font-family: "Poppins";
      letter-spacing: 0.6px;
      font-weight: 600 !important;
    }
  }
}


.woo-social-login-profile.woo-slg-social-wrap {
  display: none;
}

.woocommerce-view-order {
  .woocommerce-customer-details {
    display: none;
  }
}

.woocommerce table.my_account_orders td,
.woocommerce table.my_account_orders th {
  padding: 10px 0px !important
}

.woocommerce-MyAccount-navigation li.is-active a {
  color: #28474f !important;
  font-weight: bold !important
}

.woocommerce table.shop_table {
  border: none !important
}

.woocommerce_account_subscriptions {
  background: #fff;
  padding: 20px;

  .shop_table {
    margin: 0px !important
  }
}

.woocommerce-order-details>h2 {
  display: none
}

.woocommerce-MyAccount-content button.vermas {
  margin-top: 0px !important
}

.woocommerce form .form-row {
  margin: 0px !important
}

/* DETALLE DE LA CUENTA */
.woocommerce-edit-account {
  .woocommerce-form-row--wide span {
    width: 100%;
  }

  .woocommerce-EditAccountForm {
    fieldset {
      margin-top: 40px
    }
  }

  .show-password-input {
    display: none
  }

}

/* METODO DE PAGO */

.woocommerce-payment-methods {
  .woocommerce-MyAccount-content {
    .woocommerce-PaymentMethod--actions::before {
      display: none
    }

    >.button {
      padding: 0px 20px !important;
      border: 2px solid #28474f !important;
      height: 36px;
      line-height: 34px;
      font-size: 12px !important;
      color: #28474f !important;

      &:hover {
        background-color: #28474f !important;
        color: #fff !important
      }

    }
  }
}


.woocommerce-MyAccount-content .woocommerce-table td,
.woocommerce-MyAccount-content .shop_table td {
  padding: 10px 0px !important;
}


/* SER UN JOKER MI CUENTA */
.woocommerce-members_area {
  .woocommerce-account-my-memberships {
    .button.view {
      display: none !important
    }

    .shop_table {
      margin: 0px !important;
      padding: 0px !important;

      tr,
      td {
        text-align: left !important
      }

      a,
      td {
        letter-spacing: 0px !important
      }
    }
  }


}

/* PEDIDO MENSUAL MI CUENTA  */
.woocommerce-view-subscription {

  .woocommerce-customer-details,
  .woocommerce-MyAccount-orders {
    display: none
  }

  .woocommerce-MyAccount-content>header {
    display: none
  }
}

/* AGRRGAR TARJETA  MI CUENTA  */

.woocommerce-add-payment-method {
  .woocommerce-Payment {
    max-width: 360px;
    background-color: transparent !important;

    .woocommerce-PaymentMethods {
      border: none !important
    }

    .woocommerce-Button {
      padding: 0px 20px !important;
      border: 2px solid #28474f !important;
      height: 36px;
      line-height: 34px;
      font-size: 12px !important;
      color: #28474f !important;
      width: auto;
      background-color: transparent !important;

      &:hover {
        background-color: #28474f !important;
        color: #fff !important
      }
    }
  }



}

.woocommerce-view-order .woocommerce-MyAccount-content .woocommerce-order-details {

  header,
  .woocommerce-MyAccount-subscriptions {
    display: none
  }
}

.contenedor-login {
  .password-input {
    width: 100%
  }
}


